@charset "UTF-8";
/**
 *  Clearfix
 *
 *  Usage: append to container, that contains floated elements - @include clearfix;
 */
/**
 *  «Respond to» mixin
 *
 *  Mixin to manage responsive breakpoints
 *  @param {String} $breakpoint - Breakpoint name
 *  @author Hugo Giraudel
 *
 *  @require $breakpoints
 */
/**
Placeholder
 */
@font-face {
  font-family: 'HelveticaNeueLTStd';
  src: local("HelveticaNeueLTStdRoman"), local("HelveticaNeueLTStdRoman"), url("../fonts/Helvetica Neue LT Std 55 Roman/Helvetica Neue LT Std 55 Roman.woff2") format("woff2"), url("../fonts/Helvetica Neue LT Std 55 Roman/Helvetica Neue LT Std 55 Roman.woff") format("woff"), url("../fonts/Helvetica Neue LT Std 55 Roman/Helvetica Neue LT Std 55 Roman.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'HelveticaNeueLTStd';
  src: local("HelveticaNeueLTStdLight"), local("HelveticaNeueLTStdLight"), url("../fonts/Helvetica Neue LT Std 45 Light/Helvetica Neue LT Std 45 Light.woff2") format("woff2"), url("../fonts/Helvetica Neue LT Std 45 Light/Helvetica Neue LT Std 45 Light.woff") format("woff"), url("../fonts/Helvetica Neue LT Std 45 Light/Helvetica Neue LT Std 45 Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'HelveticaNeueLTStd';
  src: local("HelveticaNeueLTStdBold"), local("HelveticaNeueLTStdBold"), url("../fonts/Helvetica Neue LT Std 75 Bold/Helvetica Neue LT Std 75 Bold.woff2") format("woff2"), url("../fonts/Helvetica Neue LT Std 75 Bold/Helvetica Neue LT Std 75 Bold.woff") format("woff"), url("../fonts/Helvetica Neue LT Std 75 Bold/Helvetica Neue LT Std 75 Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'HelveticaNeueLTStd';
  src: local("HelveticaNeueLTStdHeavy"), local("HelveticaNeueLTStdHeavy"), url("../fonts/Helvetica Neue LT Std 85 Heavy/Helvetica Neue LT Std 85 Heavy.woff2") format("woff2"), url("../fonts/Helvetica Neue LT Std 85 Heavy/Helvetica Neue LT Std 85 Heavy.woff") format("woff"), url("../fonts/Helvetica Neue LT Std 85 Heavy/Helvetica Neue LT Std 85 Heavy.ttf") format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'BebasNeueBold';
  src: local("BebasNeueBold"), local("BebasNeueBold"), url("../fonts/BebasNueBold/BebasNeueBold.woff") format("woff2"), url("../fonts/BebasNueBold/BebasNeueBold.woff") format("woff"), url("../fonts/BebasNueBold/BebasNeueBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

:focus {
  outline: 0; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

a {
  text-decoration: none;
  color: white; }

a:focus {
  outline: none; }

a:active,
a:hover {
  outline: 0; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic; }

figure {
  margin: 0; }

form {
  margin: 0; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px; }

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

button,
input {
  line-height: normal; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible; }

button[disabled],
html input[disabled] {
  cursor: default; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  *height: 13px;
  *width: 13px; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

html, body {
  margin: 0;
  position: relative;
  height: 100%;
  overflow: hidden; }
  @media (max-width: 1200px) {
    html.active, body.active {
      overflow: hidden; } }

body {
  position: relative;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  font-family: 'HelveticaNeueLTStd';
  height: 100%;
  margin: 0;
  scrollbar-face-color: #333333;
  scrollbar-arrow-color: red;
  scrollbar-track-color: #4C4C4C;
  scrollbar-shadow-color: black;
  scrollbar-highlight-color: black;
  scrollbar-3dlight-color: #333333;
  scrollbar-darkshadow-Color: #333333;
  scrollbar-width: thin; }

::-webkit-scrollbar {
  width: 8px;
  background: #333333; }

::-webkit-scrollbar-thumb {
  width: 8px;
  height: 20px;
  background: #4C4C4C;
  border-radius: 47px; }

.scr_zoom {
  position: fixed;
  left: -3%;
  right: 0;
  bottom: -77px;
  width: 162px;
  height: 162px;
  z-index: 5001;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  margin: 0 auto;
  text-align: center; }
  @media (max-width: 768px) {
    .scr_zoom {
      width: 120px;
      height: 120px;
      bottom: -55px;
      left: 0; } }
  .scr_zoom:after {
    content: '';
    background: #03A9F5;
    width: 134px;
    height: 134px;
    position: absolute;
    top: 17px;
    left: 15px;
    right: 0;
    bottom: 0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%; }
    @media (max-width: 768px) {
      .scr_zoom:after {
        background: #03A9F5;
        width: 110px;
        height: 110px;
        position: absolute;
        top: 17px;
        left: 5px; } }
  .scr_zoom > div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    padding-top: 15%;
    z-index: 1; }
  .scr_zoom p {
    color: white;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    font-family: 'HelveticaNeueLTStd';
    font-weight: 300;
    padding-left: 2px; }
    @media (max-width: 768px) {
      .scr_zoom p {
        font-size: 11px;
        line-height: 13px; } }
  .scr_zoom img {
    position: relative;
    margin: 0 auto;
    margin-bottom: 3px;
    width: 20px;
    height: 28px; }
    @media (max-width: 768px) {
      .scr_zoom img {
        height: 21px;
        padding-top: 5px; } }

@keyframes scaleInBefore {
  from {
    transform: scale(0.3, 0.3); }
  to {
    transform: scale(1.2, 1.2);
    opacity: 0; }
  75% {
    transform: scale(1.2, 1.2);
    opacity: 0; } }

@keyframes scaleInAfter {
  from {
    transform: scale(1, 1); }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; }
  50% {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(3, 169, 245, 0.3); }
  100% {
    box-shadow: 0 0 0 20px rgba(3, 169, 245, 0); } }

@keyframes slideLeft {
  from {
    transform: translateX(-100%); }
  to {
    transform: translateX(0%); } }

@keyframes slideRight {
  from {
    transform: translateX(100%); }
  to {
    transform: translateX(0%); } }

@keyframes opacityContent {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.mfp-close-btn-in .mfp-close {
  color: #fff !important;
  background: black;
  opacity: 1;
  top: -6%; }
  @media (max-width: 1200px) {
    .mfp-close-btn-in .mfp-close {
      top: 0; } }

body .mfp-close:active {
  top: -6%; }
  @media (max-width: 1200px) {
    body .mfp-close:active {
      top: 0; } }

.mfp-bg {
  opacity: 0 !important; }

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0 !important; }

.mfp-close-btn-in .mfp-close {
  color: #02a9f5; }

.mfp-zoom-in {
  /* start state */
  /* animate in */
  /* animate out */ }
  .mfp-zoom-in .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8); }
  .mfp-zoom-in.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out; }
  .mfp-zoom-in.mfp-ready .mfp-with-anim {
    opacity: 1;
    transform: scale(1); }
  .mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: 0.8; }
  .mfp-zoom-in.mfp-removing .mfp-with-anim {
    transform: scale(0.8);
    opacity: 0; }
  .mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0; }

body .mfp-container {
  padding: 0; }

@keyframes odsoky {
  0% {
    transform: translateY(6px); }
  100% {
    transform: translateY(1px); } }

.button {
  background-color: #03A9F5;
  display: inline-block;
  padding: 0.7em 1.8em;
  line-height: 1;
  white-space: nowrap;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  transition: background-color 0.3s;
  box-sizing: border-box;
  text-align: center; }
  .button:hover {
    background-color: #0286c3; }

.main-head {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: transparent;
  height: 12%;
  z-index: 5000;
  pointer-events: none; }
  .main-head div.black {
    max-width: 30.5%;
    width: 100%;
    height: 13%;
    background: transparent;
    position: relative; }
    .main-head div.black:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background: #000000;
      bottom: 0;
      transform-origin: right top;
      transform: skewX(-37deg); }
  .main-head__wrap {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 9.5%;
    left: 0;
    right: 0; }
  .main-head__logo {
    width: 31%;
    background: transparent;
    position: relative;
    padding: 1.5% 0% 1.5% 2.5%;
    pointer-events: all; }
    @media (max-width: 768px) {
      .main-head__logo {
        width: 43%;
        padding: 0;
        padding-left: 15px; } }
    @media (max-width: 470px) {
      .main-head__logo {
        padding-left: 5px; } }
    @media (max-width: 325px) {
      .main-head__logo {
        width: 47%; } }
    .main-head__logo:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      background: #E42D19;
      bottom: 0;
      transform-origin: right top;
      transform: skewX(-30deg);
      box-shadow: 0px 0px 20px rgba(1, 1, 1, 0.15); }
      @media (max-width: 768px) {
        .main-head__logo:after {
          height: 93%;
          top: 0; } }
      @media (max-width: 525px) {
        .main-head__logo:after {
          height: 73%; } }
    .main-head__logo a {
      display: flex;
      width: 100%;
      position: relative;
      z-index: 1;
      align-items: center;
      font-family: 'BebasNeueBold';
      font-style: normal;
      font-weight: bold;
      text-transform: uppercase;
      color: #FFFFFF;
      font-size: 50px;
      line-height: 100%;
      padding-top: 5px; }
      @media (max-width: 1700px) {
        .main-head__logo a {
          font-size: 42px; } }
      @media (max-width: 1400px) {
        .main-head__logo a {
          font-size: 34px; } }
      @media (max-width: 1150px) {
        .main-head__logo a {
          font-size: 30px; } }
      @media (max-width: 992px) {
        .main-head__logo a {
          font-size: 26px; } }
      @media (max-width: 860px) {
        .main-head__logo a {
          font-size: 23px;
          padding-top: 10px; } }
      @media (max-width: 768px) {
        .main-head__logo a {
          padding-top: 18px; } }
      @media (max-width: 630px) {
        .main-head__logo a {
          padding-top: 18px;
          font-size: 20px; } }
      @media (max-width: 545px) {
        .main-head__logo a {
          height: auto;
          font-size: 17px;
          padding-top: 22px; } }
      @media (max-width: 525px) {
        .main-head__logo a {
          font-size: 17px;
          padding-top: 15px; } }
      @media (max-width: 470px) {
        .main-head__logo a {
          font-size: 13px;
          padding-top: 16px; } }
      @media (max-width: 360px) {
        .main-head__logo a {
          font-size: 11px;
          padding-top: 20px; } }
    .main-head__logo img {
      width: 100%; }
  .main-head__info {
    width: 69%; }
  .main-head__help, .main-head__menu {
    width: 10%;
    max-height: 74.1%;
    height: 100%;
    float: right;
    position: relative;
    color: #FFFFFF;
    pointer-events: all; }
    @media (max-width: 1200px) {
      .main-head__help, .main-head__menu {
        width: 70px;
        height: 60px; } }
  .main-head .white_row {
    background: white;
    float: right;
    text-align: center;
    position: relative;
    right: 3.1%;
    width: 1px;
    top: 10%;
    max-height: 74.1%;
    height: 50%;
    transform: skew(-36deg, 0deg); }
    @media (max-width: 1200px) {
      .main-head .white_row {
        display: none; } }
  .main-head__help {
    position: relative;
    right: 1.5%; }
    .main-head__help:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #000000;
      transform-origin: right top;
      transform: skewX(-36deg);
      box-shadow: 0px 0px 20px rgba(1, 1, 1, 0.15);
      transition: all, .7s ease-in-out; }
    .main-head__help:hover:after {
      background: #333333; }
    .main-head__help > a {
      position: relative;
      z-index: 1;
      padding-top: 20%;
      padding-left: 15%;
      cursor: pointer;
      width: 45%;
      display: block; }
      @media (max-width: 1500px) {
        .main-head__help > a {
          padding-top: 15%;
          padding-left: 11%; } }
      @media (max-width: 1200px) {
        .main-head__help > a {
          padding-top: 15%; } }
    .main-head__help img {
      width: auto;
      height: auto;
      object-fit: contain;
      display: block; }
      @media (max-width: 1200px) {
        .main-head__help img {
          width: 12px; } }
    .main-head__help span {
      display: block;
      left: -5px;
      padding-top: 2px;
      position: relative;
      font-family: 'HelveticaNeueLTStd';
      font-weight: 300;
      font-size: 70%;
      line-height: 17px;
      text-transform: uppercase; }
      @media (max-width: 1200px) {
        .main-head__help span {
          font-size: 60%;
          bottom: 3px; } }
  .main-head__menu {
    position: relative;
    right: 0;
    transition: all, .3s ease-in-out; }
    .main-head__menu:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #000000;
      transform-origin: right top;
      transform: skewX(-36deg);
      box-shadow: 0px 0px 20px rgba(1, 1, 1, 0.15);
      transition: all, .3s ease-in-out; }
    .main-head__menu.active:after {
      background: #333333; }
    .main-head__menu:hover:after {
      background: #333333; }
    .main-head__menu > div {
      position: relative;
      z-index: 1;
      padding-top: 20%;
      padding-left: 15%;
      cursor: pointer;
      width: 45%;
      display: block; }
      @media (max-width: 1500px) {
        .main-head__menu > div {
          padding-top: 15%;
          padding-left: 11%; } }
      @media (max-width: 1200px) {
        .main-head__menu > div {
          padding-top: 9%; } }
    .main-head__menu .btn_menu {
      cursor: pointer;
      position: relative;
      height: 17px; }
      .main-head__menu .btn_menu:before {
        -webkit-transition: all .3s ease;
        -ms-transition: all .3s ease;
        transition: all .3s ease;
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        height: 2px;
        width: 48%;
        background: #FFFFFF;
        content: ''; }
      .main-head__menu .btn_menu:after {
        position: absolute;
        bottom: 6px;
        left: 0;
        display: block;
        height: 4px;
        width: 48%;
        border-top: 2px solid #FFFFFF;
        border-bottom: 2px solid #FFFFFF;
        content: ''; }
        @media (max-width: 1200px) {
          .main-head__menu .btn_menu:after {
            height: 2px;
            bottom: 4px; } }
    .main-head__menu #btn_menu_wpap.opened .btn_menu:before {
      bottom: 8px;
      background: #fff;
      width: 48%;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg); }
      @media (max-width: 1200px) {
        .main-head__menu #btn_menu_wpap.opened .btn_menu:before {
          bottom: 6px; } }
    .main-head__menu #btn_menu_wpap.opened .btn_menu:after {
      -webkit-transition: all .3s ease;
      -ms-transition: all .3s ease;
      transition: all .3s ease;
      bottom: 8px;
      border: 0;
      height: 2px;
      width: 48%;
      background: #fff;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg); }
      @media (max-width: 1200px) {
        .main-head__menu #btn_menu_wpap.opened .btn_menu:after {
          bottom: 6px; } }
    .main-head__menu span {
      display: block;
      left: -5%;
      padding-top: 2px;
      position: relative;
      font-family: 'HelveticaNeueLTStd';
      font-weight: 300;
      font-size: 70%;
      line-height: 17px;
      text-transform: uppercase; }
      @media (max-width: 1700px) {
        .main-head__menu span {
          left: -8%; } }
      @media (max-width: 1500px) {
        .main-head__menu span {
          left: -13%; } }
      @media (max-width: 1400px) {
        .main-head__menu span {
          left: -15%; } }
      @media (max-width: 1200px) {
        .main-head__menu span {
          font-size: 60%;
          bottom: 3px; } }

.navigation {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 11.5%;
  background: #333333;
  width: 17%;
  overflow-y: auto;
  padding: 29px 37px 10px 33px;
  transform: translateX(100%);
  transition: all, .2s ease;
  z-index: 99; }
  @media (max-width: 1200px) {
    .navigation {
      width: 250px;
      top: 58px; } }
  .navigation.opened {
    right: 8px;
    transform: translateX(0%);
    transition: all, .2s ease; }
    @media (max-width: 768px) {
      .navigation.opened {
        right: 0; } }

.my-navigation ul li {
  display: inline-block; }
  .my-navigation ul li span,
  .my-navigation ul li a {
    font-family: 'HelveticaNeueLTStd';
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #F8C000;
    margin-bottom: 12%;
    display: block; }
    @media (max-width: 768px) {
      .my-navigation ul li span,
      .my-navigation ul li a {
        margin-bottom: 15px; } }
  .my-navigation ul li ul {
    transition: .3s ease-in; }
    .my-navigation ul li ul li {
      margin-bottom: 15%;
      transition: .3s ease-in;
      position: relative; }
      .my-navigation ul li ul li:before {
        transition: .3s ease-in;
        position: absolute;
        display: block;
        width: 17px;
        height: 8px;
        left: -25px;
        top: 29%;
        content: '';
        background: transparent;
        transform: skew(-30deg); }
      @media (max-width: 1400px) {
        .my-navigation ul li ul li {
          margin-bottom: 9%; } }
      @media (max-width: 768px) {
        .my-navigation ul li ul li {
          margin-bottom: 15px; } }
      .my-navigation ul li ul li a {
        color: #FFFFFF;
        text-transform: none;
        margin-bottom: 0;
        transition: .3s ease-in; }
      .my-navigation ul li ul li:hover:before {
        transition: .3s ease-in;
        background: #03A9F5; }
      .my-navigation ul li ul li:hover a {
        color: #03A9F5; }

.video-popup {
  position: absolute;
  display: none;
  z-index: 5000;
  bottom: -3.8em;
  left: 0;
  width: calc(50% - 1.6em);
  color: #fff;
  padding: 0;
  box-sizing: border-box;
  pointer-events: all;
  max-height: calc(100vh - 140px - 100%);
  transform: translate(-100%, 100%);
  transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  max-width: 940px; }
  @media screen and (max-width: 1024px) {
    .video-popup {
      width: 100%; } }
  .video-popup.is--initialized {
    display: block; }
  .video-popup--is-open {
    transform: translate(0, 100%); }
    .video-popup--is-open .slider-video h2 {
      opacity: 1 !important;
      transition: opacity 0.45s 0.95s; }
    @media screen and (max-width: 1024px) {
      .video-popup--is-open + .details {
        transform: translate(100%, 100%); } }
    .video-popup--is-open .details__close {
      opacity: 1;
      transition: opacity 0.45s 1.15s; }
  .video-popup__content {
    padding-bottom: 56.2%;
    position: relative; }
    .video-popup__content video, .video-popup__content iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center; }
    .video-popup__content .slider-video {
      position: relative; }
      .video-popup__content .slider-video video, .video-popup__content .slider-video iframe {
        background: #000; }
      .video-popup__content .slider-video .slick-slide .wrap {
        position: relative;
        padding-bottom: 56.20%;
        height: 0;
        overflow: hidden;
        margin-bottom: 10px; }
        @media screen and (max-width: 800px) {
          .video-popup__content .slider-video .slick-slide .wrap {
            padding-bottom: 56.25%; } }
      .video-popup__content .slider-video .slick-slide .wrap-video {
        position: relative;
        overflow: hidden;
        padding-bottom: 1px; }
      .video-popup__content .slider-video video {
        position: relative;
        top: auto;
        left: auto;
        width: 100%;
        height: auto;
        max-height: 100%; }
      .video-popup__content .slider-video iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-width: 0;
        outline-width: 0; }
      .video-popup__content .slider-video h2 {
        font-family: 'HelveticaNeueLTStd';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 25px;
        text-align: center;
        color: #FFFFFF;
        background: #000000;
        margin-left: 13%;
        margin-right: 16%;
        height: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        position: relative;
        top: -1px; }
        @media screen and (max-width: 1200px) {
          .video-popup__content .slider-video h2 {
            font-size: 14px; } }
        @media screen and (max-width: 1024px) {
          .video-popup__content .slider-video h2 {
            margin: 0% 6%; } }
        @media screen and (max-width: 800px) {
          .video-popup__content .slider-video h2 {
            margin-left: 9%;
            margin-right: 9%;
            height: 23px; } }
        @media screen and (max-width: 340px) {
          .video-popup__content .slider-video h2 {
            font-size: 11px; } }
      .video-popup__content .slider-video .slick-arrow {
        position: absolute;
        bottom: 0;
        transform-origin: right top;
        -webkit-transform: skewX(-36deg);
        transform: skewX(-36deg);
        border: none;
        z-index: 2;
        font-size: 0;
        line-height: 0;
        width: 86px;
        height: 40px;
        background: #03A9F5; }
        @media screen and (max-width: 800px) {
          .video-popup__content .slider-video .slick-arrow {
            width: 46px;
            height: 25px; } }
      .video-popup__content .slider-video .slick-next {
        right: 8%; }
        @media screen and (max-width: 1024px) {
          .video-popup__content .slider-video .slick-next {
            right: 0%; } }
        @media screen and (max-width: 800px) {
          .video-popup__content .slider-video .slick-next {
            right: 4%; } }
        .video-popup__content .slider-video .slick-next:before {
          content: '';
          background-image: url("../img/row.png");
          background-size: auto;
          background-repeat: no-repeat;
          background-position: center;
          -webkit-transform: skewX(36deg);
          transform: skewX(36deg) rotate(180deg);
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          position: absolute; }
      .video-popup__content .slider-video .slick-prev {
        left: 12%; }
        @media screen and (max-width: 1024px) {
          .video-popup__content .slider-video .slick-prev {
            left: 0%; } }
        @media screen and (max-width: 800px) {
          .video-popup__content .slider-video .slick-prev {
            left: 8%; } }
        .video-popup__content .slider-video .slick-prev:before {
          content: '';
          background-image: url("../img/row.png");
          background-size: auto;
          background-repeat: no-repeat;
          background-position: center;
          -webkit-transform: skewX(36deg);
          transform: skewX(36deg);
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          position: absolute; }
  .video-popup .details__close {
    top: 0; }

.details {
  position: absolute;
  display: none;
  z-index: 5000;
  background: #000;
  bottom: -3.8em;
  right: 0;
  width: calc(50% - 1.6em);
  color: #fff;
  padding: 0;
  box-sizing: border-box;
  pointer-events: all;
  max-height: calc(100vh - 140px - 100%);
  transform: translate(100%, 100%);
  transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  max-width: 940px; }
  @media screen and (max-width: 1024px) {
    .details {
      width: 100%; } }
  @media screen and (min-width: 1441px) {
    .details {
      padding: 4.2em; } }
  .details.is--initialized {
    display: flex; }
  .details--is-open {
    transform: translate(0, 100%); }
    @media screen and (min-width: 1441px) {
      .details--is-open .details__header {
        transform: translateX(0) skewX(-35deg);
        transition: transform 0.45s 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86); } }
    .details--is-open .details__content {
      opacity: 1;
      transition: opacity 0.45s 0.95s; }
    .details--is-open .details__close {
      opacity: 1;
      transition: opacity 0.45s 1.15s; }
    .details--is-open .details__footer {
      opacity: 1;
      transition: opacity 0.45s 1.25s; }
  .details__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 4999; }
    body.active .details__backdrop {
      pointer-events: all; }
  .details__inner {
    width: 100%;
    overflow: auto; }
    @media screen and (min-width: 1025px) {
      .details__inner {
        display: flex;
        flex-direction: column;
        overflow: hidden; } }
  .details__close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    background: #000;
    border: none;
    outline: none;
    display: block;
    width: 3.1em;
    height: 3.1em;
    padding: 0;
    opacity: 0;
    transition: opacity 0.55s; }
    @media screen and (min-width: 1441px) {
      .details__close {
        top: -3.2em; } }
    .details__close svg {
      width: 100%;
      height: 100%;
      display: block; }
  .details__content {
    flex: 1;
    padding: 2em 2em 3em;
    opacity: 0;
    transition: opacity 0.55s; }
    @media screen and (min-width: 1025px) {
      .details__content {
        max-height: 360px;
        overflow: auto; } }
    @media screen and (min-width: 1441px) {
      .details__content {
        max-width: 50%;
        padding: 0 0.5em 3em 0; } }
    .details__content::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #333; }
    .details__content::-webkit-scrollbar {
      width: 3px;
      background-color: #000; }
    .details__content::-webkit-scrollbar-thumb {
      background-color: #E42D19;
      border-radius: 0; }
    .details__content a.link {
      color: #F8C000;
      display: inline-block;
      line-height: 100%;
      white-space: nowrap;
      letter-spacing: 0.3px;
      transition: all, 0.3s;
      box-sizing: border-box;
      text-align: center;
      margin-top: 15px;
      border: none;
      width: auto;
      text-transform: none;
      padding: 0;
      margin-left: 0 !important;
      font-size: 16px; }
      @media screen and (max-width: 1499px) {
        .details__content a.link {
          display: block;
          margin-top: 15px;
          width: auto; } }
      .details__content a.link:hover {
        opacity: .9;
        border: none; }
    .details__content a {
      color: #F8C000;
      border: 1px solid #03A9F5;
      display: inline-block;
      padding: 0.7em 1.8em;
      line-height: 100%;
      white-space: nowrap;
      letter-spacing: 0.3px;
      transition: all, 0.3s;
      box-sizing: border-box;
      text-align: center;
      width: 130px;
      margin-top: 15px; }
      @media screen and (max-width: 1499px) {
        .details__content a {
          display: block;
          margin-top: 15px;
          width: 230px; } }
      @media screen and (max-width: 575px) {
        .details__content a {
          width: 100%; } }
      .details__content a:hover {
        border: 1px solid #F8C000; }
    .details__content a:last-child {
      margin-left: 50px; }
      @media screen and (max-width: 1579px) {
        .details__content a:last-child {
          margin-left: 20px; } }
      @media screen and (max-width: 1499px) {
        .details__content a:last-child {
          margin-top: 15px;
          margin-left: 0; } }
  .details__title {
    font-size: 1.6em;
    letter-spacing: 0.3px;
    font-weight: 800;
    word-spacing: 1px;
    margin-bottom: 1.4em; }
  .details__header {
    position: relative;
    background-color: #fff;
    padding-bottom: 40%;
    overflow: hidden;
    width: 100%; }
    @media screen and (min-width: 1441px) {
      .details__header {
        position: absolute;
        padding-bottom: 35%;
        width: 47%;
        top: -3.2em;
        right: -12%;
        transform: translateX(100%) skewX(-35deg);
        transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86); } }
  .details__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center; }
    @media screen and (min-width: 1441px) {
      .details__image {
        left: -26%;
        width: 102%;
        transform: skewX(35deg); } }
  .details__footer {
    padding: 0 2.5em 2.5em;
    overflow: auto;
    opacity: 0;
    transition: opacity 0.55s; }
    @media screen and (min-width: 1441px) {
      .details__footer {
        padding: 3.8em 0 0.5em; } }
    .details__footer::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #333; }
    .details__footer::-webkit-scrollbar {
      width: 3px;
      background-color: #000; }
    .details__footer::-webkit-scrollbar-thumb {
      background-color: #E42D19;
      border-radius: 0; }
    .details__footer-content {
      margin: 0 auto;
      max-width: 490px; }
    .details__footer .button {
      min-width: 224px; }
      @media screen and (max-width: 1200px) {
        .details__footer .button {
          margin-bottom: 1em; } }
  .details__video {
    position: relative;
    padding-bottom: 55.5%;
    margin-bottom: 4.8em; }
    .details__video iframe {
      position: absolute;
      width: 100%;
      height: 100%; }
  .details__links {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; }
    @media screen and (min-width: 1201px) {
      .details__links a:not(:last-child) {
        margin-right: 1.5em; } }
    @media screen and (max-width: 1200px) {
      .details__links {
        flex-direction: column; } }
  .details p {
    margin-bottom: 1.5em; }
  .details ul {
    list-style: none;
    padding: 0 1em 0 0;
    margin: 0; }
    .details ul li {
      display: flex;
      line-height: 1.3;
      margin-bottom: 1.15em; }
      .details ul li::before {
        content: '';
        display: inline-block;
        width: 11px;
        max-width: 11px;
        flex: 0 0 11px;
        height: 8px;
        background-color: #90C241;
        transform: skewX(-31deg);
        margin: 0.3em 1em 0 0.2em; }

.scene {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate3d(0px, 0px, 0px);
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transition: transform 0.75s ease; }
  body.is--touch .scene {
    overflow: auto; }
  .scene__input {
    display: block;
    position: relative;
    pointer-events: none; }
    body.is--touch .scene__input {
      overflow: hidden; }
    .scene__input img {
      position: absolute;
      width: 140%;
      height: 140%;
      left: -20%;
      top: -20%; }
      .scene__input img[data-scene-bg] {
        position: relative; }
  .scene .scene-layers {
    display: inline-block;
    max-width: none;
    transform-style: preserve-3d;
    backface-visibility: hidden; }
  .scene .scene-zoom {
    display: block;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    transition: transform 0.75s ease; }
  .scene .mask_color {
    clip-path: circle(75px at 0 0); }
  .scene .wrap_hotspots {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    pointer-events: none; }
    .scene .wrap_hotspots .hotspot {
      pointer-events: all;
      position: absolute;
      cursor: pointer;
      width: 20px;
      height: 20px;
      transition: 1.5s ease;
      border-radius: 50%; }
      body.active .scene .wrap_hotspots .hotspot:not(.active) {
        opacity: 0; }
      .scene .wrap_hotspots .hotspot span {
        background: #90C241;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        transition: 2s ease; }
      .scene .wrap_hotspots .hotspot:before {
        content: '';
        width: 40px;
        height: 40px;
        top: -50%;
        left: -50%;
        position: absolute;
        background: rgba(144, 194, 65, 0.25);
        border-radius: 50%;
        z-index: 2; }
      .scene .wrap_hotspots .hotspot:after {
        content: '';
        width: 60px;
        height: 60px;
        position: absolute;
        top: -100%;
        left: -100%;
        background: rgba(144, 194, 65, 0.25);
        border-radius: 50%;
        z-index: 2; }
      .scene .wrap_hotspots .hotspot:hover:before, .scene .wrap_hotspots .hotspot.is--hover:before {
        animation: scaleInBefore 1s infinite cubic-bezier(0.28, 0.23, 0.33, 0.32); }
      .scene .wrap_hotspots .hotspot:hover:after, .scene .wrap_hotspots .hotspot.is--hover:after {
        animation: scaleInAfter 1s infinite cubic-bezier(0.28, 0.23, 0.33, 0.32); }
      .scene .wrap_hotspots .hotspot.active span, .scene .wrap_hotspots .hotspot.visited span {
        background: #e42d19; }
      .scene .wrap_hotspots .hotspot.active:before, .scene .wrap_hotspots .hotspot.visited:before {
        background: rgba(228, 45, 25, 0.25); }
      .scene .wrap_hotspots .hotspot.active:after, .scene .wrap_hotspots .hotspot.visited:after {
        background: rgba(228, 45, 25, 0.25); }
      .scene .wrap_hotspots .hotspot.is--hover {
        background: #03a9f5; }
        .scene .wrap_hotspots .hotspot.is--hover:before {
          background: rgba(3, 169, 245, 0.25); }
        .scene .wrap_hotspots .hotspot.is--hover:after {
          background: rgba(3, 169, 245, 0.25); }

.texture_top {
  position: absolute;
  top: 1.8%;
  left: 0;
  width: 100%;
  max-width: 30%;
  pointer-events: none; }
  .texture_top svg {
    max-width: 100%;
    width: 100%; }

.texture_bottom {
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none; }
  .texture_bottom svg {
    max-width: 100%;
    width: 100%; }

.subtitle_hotspot {
  position: absolute;
  background: #333333;
  top: 4.2%;
  left: 32%;
  max-width: 100%;
  width: auto;
  padding: 1% 3% 1% 2%;
  text-align: left;
  visibility: hidden;
  opacity: 0;
  transform: skewX(-31deg);
  transition: all, .1s ease;
  z-index: 999; }
  @media (max-width: 1600px) {
    .subtitle_hotspot {
      top: 3.3%; } }
  @media (max-width: 1200px) {
    .subtitle_hotspot {
      top: 90px;
      padding: 5px 15px;
      left: -15px; } }
  @media (max-width: 992px) {
    .subtitle_hotspot {
      top: 75px; } }
  @media (max-width: 768px) {
    .subtitle_hotspot {
      top: 70px; } }
  @media (max-width: 525px) {
    .subtitle_hotspot {
      top: 60px; } }
  .subtitle_hotspot h1 {
    padding: 0 0px 0 20px;
    width: 100%;
    font-family: 'HelveticaNeueLTStd';
    font-weight: 800;
    font-style: normal;
    font-size: 110%;
    line-height: 156%;
    text-align: left;
    text-transform: uppercase;
    color: #F8C000;
    z-index: 1;
    pointer-events: none;
    opacity: 0;
    transform: skewX(31deg);
    transition: all, .5s ease; }
    @media (max-width: 1400px) {
      .subtitle_hotspot h1 {
        font-size: 18px; } }
    @media (max-width: 1200px) {
      .subtitle_hotspot h1 {
        font-size: 12px; } }
    @media (max-width: 768px) {
      .subtitle_hotspot h1 {
        font-size: 12px; } }
  .subtitle_hotspot.active {
    visibility: visible;
    opacity: 1;
    transform: skewX(-31deg); }
    .subtitle_hotspot.active h1 {
      animation: opacityContent .4s;
      animation-fill-mode: forwards;
      animation-delay: .2s; }

.hotspot1 {
  top: 32%;
  left: 20.2%;
  transform: scale(0.67); }

.hotspot2 {
  top: 33%;
  left: 23.2%;
  transform: scale(0.67); }

.hotspot3 {
  top: 27%;
  left: 20.2%;
  transform: scale(0.67); }

.hotspot4 {
  top: 27.5%;
  left: 22.2%;
  transform: scale(0.67); }

.hotspot5 {
  top: 27%;
  left: 16.4%;
  transform: scale(0.67); }

.hotspot6 {
  top: 22%;
  left: 20.1%;
  transform: scale(0.67); }

.hotspot7 {
  top: 18%;
  left: 20.1%;
  transform: scale(0.67); }

.hotspot8 {
  top: 25%;
  left: 26.4%;
  transform: scale(0.67); }

.hotspot9 {
  bottom: 9%;
  left: 18.8%;
  transform: scale(0.67); }

.hotspot10 {
  bottom: 9%;
  left: 20.8%;
  transform: scale(0.67); }

.hotspot11 {
  bottom: 11%;
  left: 20%;
  transform: scale(0.67); }

.hotspot12 {
  bottom: 16.5%;
  left: 20%;
  transform: scale(0.67); }

.hotspot13 {
  bottom: 19.3%;
  left: 16.7%;
  transform: scale(0.67); }

.hotspot14 {
  bottom: 17%;
  left: 18.8%;
  transform: scale(0.67); }

.hotspot15 {
  bottom: 22.2%;
  left: 15.4%; }

.hotspot16 {
  bottom: 17%;
  left: 20.8%;
  transform: scale(0.67); }

.hotspot17 {
  top: 23%;
  left: 46%; }

.hotspot18 {
  top: 28%;
  left: 44.8%;
  -webkit-transform: scale(0.67);
  transform: scale(0.67); }

.hotspot19 {
  top: 29.2%;
  left: 45.7%; }

.hotspot20 {
  top: 26%;
  left: 41%;
  -webkit-transform: scale(0.67);
  transform: scale(0.67); }

.hotspot21 {
  top: 24%;
  left: 41.5%;
  -webkit-transform: scale(0.67);
  transform: scale(0.67); }

.hotspot22 {
  top: 28.2%;
  right: 31.7%;
  -webkit-transform: scale(0.67);
  transform: scale(0.67); }

.hotspot23 {
  bottom: 25.7%;
  right: 37.7%; }

.hotspot24 {
  top: 28%;
  right: 12%; }

.hotspot25 {
  bottom: 10.7%;
  right: 35.3%; }

.hotspot26 {
  bottom: 39%;
  right: 13.7%;
  -webkit-transform: scale(0.67);
  transform: scale(0.67); }

/*******TOOLTIP*********/
.wrap_tooptip {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: 0 !important;
  backface-visibility: hidden;
  visibility: hidden;
  opacity: .40;
  transition: all 0.3s ease-in-out;
  overflow: hidden; }
  .wrap_tooptip.fade {
    visibility: visible;
    opacity: 1;
    transition: .3s ease-in; }
  .wrap_tooptip .t_container {
    position: absolute;
    width: 50vw;
    height: 44vh;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    @media (max-width: 1200px) {
      .wrap_tooptip .t_container {
        width: 700px;
        max-width: 90%;
        height: 68vh;
        overflow: hidden;
        bottom: 14vh !important;
        margin: 0 auto;
        left: 0 !important;
        right: 0 !important;
        top: auto !important; } }
    @media (max-width: 768px) {
      .wrap_tooptip .t_container {
        bottom: 9vh; } }
    .wrap_tooptip .t_container:before {
      content: '';
      display: inline-block; }
  .wrap_tooptip .t_content {
    position: relative;
    display: block;
    margin: 0 auto;
    text-align: left;
    z-index: 1045; }
    .wrap_tooptip .t_content .close {
      position: absolute;
      right: -1.6%;
      top: -7%;
      width: 4%;
      height: 8%;
      color: #FFFFFF;
      background: #000;
      z-index: 3;
      display: block;
      cursor: pointer; }
      @media (max-width: 1200px) {
        .wrap_tooptip .t_content .close {
          top: 10px;
          right: 10px;
          width: 40px;
          height: 20px; } }
      .wrap_tooptip .t_content .close:hover {
        opacity: .9; }
      .wrap_tooptip .t_content .close:before {
        transition: all .3s ease;
        top: 50%;
        left: 26%;
        position: absolute;
        border: 0;
        height: 2px;
        width: 47%;
        content: '';
        background: #fff;
        transform: rotate(-45deg);
        display: block; }
      .wrap_tooptip .t_content .close:after {
        content: '';
        transition: all .3s ease;
        top: 50%;
        left: 26%;
        position: absolute;
        border: 0;
        height: 2px;
        width: 47%;
        background: #fff;
        transform: rotate(45deg);
        display: block; }

.tooltip_hotspod {
  width: 100%;
  max-height: 528px;
  height: 100%;
  margin: 0 auto;
  box-shadow: 0px 0px 20px rgba(1, 1, 1, 0.15);
  position: relative; }
  .tooltip_hotspod .box_overfloy {
    max-width: 1071px;
    width: 100%;
    max-height: 528px;
    height: 100%;
    margin: 0 auto;
    background: #000000; }
    @media (max-width: 1200px) {
      .tooltip_hotspod .box_overfloy {
        overflow: hidden; } }
    .tooltip_hotspod .box_overfloy span.figure {
      background-color: black;
      display: inline-block;
      height: 40px;
      position: absolute;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      width: 40px;
      position: absolute;
      z-index: 2;
      top: -3%; }
      @media (max-width: 1200px) {
        .tooltip_hotspod .box_overfloy span.figure {
          display: none; } }
  .tooltip_hotspod .content {
    display: flex;
    position: relative;
    max-height: 100%;
    z-index: 2; }
    @media (max-width: 992px) {
      .tooltip_hotspod .content {
        flex-direction: column;
        display: block;
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100%;
        max-height: 67vh; } }
  .tooltip_hotspod .description {
    width: 45%;
    color: white;
    margin: 50px 0px 5px 80px;
    padding-right: 10px;
    position: relative;
    z-index: 2;
    height: 40vh;
    max-height: 100%;
    overflow-y: auto;
    display: block;
    scrollbar-color: #333333 #4C4C4C;
    scrollbar-width: thin; }
    @media (max-width: 1450px) {
      .tooltip_hotspod .description {
        margin: 30px 20px 5px 40px; } }
    @media (max-width: 992px) {
      .tooltip_hotspod .description {
        width: 65%; } }
    @media (max-width: 992px) {
      .tooltip_hotspod .description {
        width: auto;
        margin: 20px;
        height: auto;
        overflow: initial; } }
    @media (max-width: 992px) {
      .tooltip_hotspod .description {
        margin: 20px 20px 5px 20px; } }
    .tooltip_hotspod .description h2 {
      font-family: 'HelveticaNeueLTStd';
      font-style: normal;
      font-weight: 800;
      font-size: 26px;
      line-height: 156%;
      text-transform: capitalize;
      margin-bottom: 35px; }
      @media (max-width: 1450px) {
        .tooltip_hotspod .description h2 {
          font-size: 22px;
          margin-bottom: 10px; } }
      @media (max-width: 1200px) {
        .tooltip_hotspod .description h2 {
          text-align: center; } }
      @media (max-width: 992px) {
        .tooltip_hotspod .description h2 {
          margin-bottom: 20px;
          font-size: 16px; } }
    .tooltip_hotspod .description p,
    .tooltip_hotspod .description li {
      font-family: 'HelveticaNeueLTStd';
      font-weight: 300;
      font-size: 16px;
      line-height: 25px;
      color: #FFFFFF; }
      @media (max-width: 992px) {
        .tooltip_hotspod .description p,
        .tooltip_hotspod .description li {
          font-size: 14px;
          line-height: 17px; } }
    .tooltip_hotspod .description p {
      margin-bottom: 23px; }
      @media (max-width: 1450px) {
        .tooltip_hotspod .description p {
          margin-bottom: 10px; } }
      @media (max-width: 992px) {
        .tooltip_hotspod .description p {
          margin-bottom: 20px; } }
    .tooltip_hotspod .description ul li {
      margin-bottom: 35px;
      display: block;
      position: relative;
      padding-left: 35px; }
      @media (max-width: 1450px) {
        .tooltip_hotspod .description ul li {
          margin-bottom: 10px; } }
      .tooltip_hotspod .description ul li:before {
        content: '';
        position: absolute;
        display: block;
        width: 17px;
        height: 8px;
        left: 3px;
        top: 5px;
        background: #90C241;
        transform: skew(-30deg); }
  .tooltip_hotspod .photo {
    width: 55%;
    background: transparent;
    position: relative;
    background: black; }
    @media (max-width: 1200px) {
      .tooltip_hotspod .photo {
        width: 45%;
        padding-top: 40px; } }
    @media (max-width: 992px) {
      .tooltip_hotspod .photo {
        margin: 0 auto; } }
    @media (max-width: 768px) {
      .tooltip_hotspod .photo {
        width: 100%; } }
    .tooltip_hotspod .photo .mask {
      background: transparent;
      position: relative;
      transform-origin: left bottom;
      transform: skewX(-31deg);
      overflow: hidden;
      height: 36vh;
      max-height: 100%;
      top: -7%;
      right: -3%;
      z-index: 2; }
      @media (max-width: 1200px) {
        .tooltip_hotspod .photo .mask {
          height: auto;
          top: auto;
          right: auto;
          overflow: visible;
          padding: 0 20px; } }
    .tooltip_hotspod .photo img {
      width: 100%;
      display: block;
      height: 100%;
      object-fit: cover;
      object-position: right;
      position: relative;
      -webkit-transform-origin: left bottom;
      transform-origin: left bottom;
      -webkit-transform: skewX(31deg);
      transform: skewX(31deg);
      background: white; }
      @media (max-width: 1200px) {
        .tooltip_hotspod .photo img {
          width: 100%;
          margin: auto;
          height: 100%;
          -o-object-fit: contain;
          object-fit: contain;
          -o-object-position: center;
          object-position: left; } }
  .tooltip_hotspod .links {
    width: 93%;
    display: block;
    text-align: right;
    padding-bottom: 20px;
    padding-top: 20px;
    padding-right: 30px;
    margin-right: 30px;
    text-transform: uppercase; }
    @media (max-width: 1200px) {
      .tooltip_hotspod .links {
        text-align: center;
        width: 100%;
        height: 60px; } }
    @media (max-width: 768px) {
      .tooltip_hotspod .links {
        padding-top: 20px; } }
    .tooltip_hotspod .links .btn_link {
      font-family: 'HelveticaNeueLTStd';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 25px;
      text-align: center;
      background: transparent;
      transition: 0.25s;
      border: 2px solid #3CAAF8;
      padding: 8.5px 46.5px;
      box-shadow: inset 0 0 0 2em #3CAAF8;
      color: #fff; }
      .tooltip_hotspod .links .btn_link:hover, .tooltip_hotspod .links .btn_link:focus {
        color: #3CAAF8;
        box-shadow: none; }

/**********POPAP*********/
.popup_hotspod {
  width: 100%;
  top: 10%;
  height: 100%;
  background: transparent;
  position: relative; }
  @media (max-width: 1200px) {
    .popup_hotspod {
      top: 0vh;
      width: 90%;
      margin: 0 auto; } }
  .popup_hotspod .box-content {
    position: relative;
    max-height: 100%;
    height: 65vh; }
    @media (max-width: 1200px) {
      .popup_hotspod .box-content {
        overflow: hidden;
        height: 68vh; } }
    .popup_hotspod .box-content svg {
      position: absolute;
      z-index: 2;
      width: 29%;
      height: 35%;
      left: -25px; }
      @media (max-width: 1200px) {
        .popup_hotspod .box-content svg {
          z-index: 3; } }
  .popup_hotspod .tab-content {
    display: none;
    background: transparent;
    width: 100%;
    height: 70vh;
    overflow: hidden;
    color: #ffffff; }
    @media (max-width: 1200px) {
      .popup_hotspod .tab-content {
        overflow-y: scroll;
        height: 100%; } }
  .popup_hotspod .content-left {
    width: 100%;
    height: 100%;
    transform: translateX(0);
    animation: slideLeft 0.4s;
    display: flex;
    position: relative; }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .popup_hotspod .content-left {
        animation: none; } }
    @media (max-width: 1200px) {
      .popup_hotspod .content-left {
        overflow: hidden;
        height: auto; } }
    @media (max-width: 768px) {
      .popup_hotspod .content-left {
        animation: none; } }
  .popup_hotspod .box-content_tab {
    text-align: left;
    position: relative;
    max-height: 100%;
    width: 100%;
    transform-origin: right top;
    transform: skewX(-31deg);
    background: #333333;
    padding-left: 15%; }
    .popup_hotspod .box-content_tab:before {
      content: '';
      transform-origin: right top;
      transform: skewX(31deg);
      position: relative;
      background-image: url("assets/dist/svg/ep.svg");
      background-size: inherit;
      background-repeat: no-repeat;
      background-size: 28rem;
      background-repeat: no-repeat;
      background-position: 2% 0%;
      max-width: 520px;
      max-height: 135px;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: -7px; }
    @media (max-width: 1200px) {
      .popup_hotspod .box-content_tab {
        padding-left: 17%;
        transform: skewX(0deg);
        padding: 20px; } }
    .popup_hotspod .box-content_tab .text {
      transform-origin: right top;
      transform: skewX(31deg);
      position: relative;
      width: 50%;
      max-height: 53vh;
      height: 100%;
      margin: 5% 2%;
      opacity: 0;
      animation: opacityContent .4s;
      animation-fill-mode: forwards;
      animation-delay: .2s;
      overflow-y: auto;
      position: relative;
      z-index: 2;
      scrollbar-color: #333333 #4C4C4C;
      scrollbar-width: thin; }
      @media (max-width: 1400px) {
        .popup_hotspod .box-content_tab .text {
          margin: 5% 6%; } }
      @media (max-width: 1200px) {
        .popup_hotspod .box-content_tab .text {
          width: 100%;
          height: auto;
          transform: skewX(0deg);
          padding: 0;
          max-height: 100%;
          margin: 0; } }
      .popup_hotspod .box-content_tab .text a {
        display: block;
        font-size: 18px;
        margin-bottom: 25px;
        color: #B0B0B0;
        text-decoration: underline;
        text-decoration-color: #F8C000;
        padding-bottom: 4px; }
        .popup_hotspod .box-content_tab .text a:hover {
          text-decoration: none; }
      .popup_hotspod .box-content_tab .text ul li {
        margin-bottom: 20px;
        display: block;
        position: relative;
        padding-left: 35px;
        font-family: 'HelveticaNeueLTStd';
        font-weight: 300;
        font-size: 16px;
        line-height: 25px;
        color: #B0B0B0; }
        @media (max-width: 1450px) {
          .popup_hotspod .box-content_tab .text ul li {
            margin-bottom: 10px; } }
        .popup_hotspod .box-content_tab .text ul li:before {
          content: '';
          position: absolute;
          display: block;
          width: 17px;
          height: 8px;
          left: 3px;
          top: 5px;
          background: #F8C000;
          transform: skew(-30deg); }
    .popup_hotspod .box-content_tab h2 {
      font-family: 'HelveticaNeueLTStd';
      font-weight: 800;
      font-size: 51px;
      line-height: 110%;
      text-align: left;
      text-transform: capitalize;
      color: #FFFFFF; }
      @media (max-width: 1600px) {
        .popup_hotspod .box-content_tab h2 {
          font-size: 45px; } }
      @media (max-width: 1470px) {
        .popup_hotspod .box-content_tab h2 {
          font-size: 45px;
          line-height: 120%; } }
      @media (max-width: 1200px) {
        .popup_hotspod .box-content_tab h2 {
          font-size: 40px;
          line-height: 110%;
          text-align: center; } }
      @media (max-width: 768px) {
        .popup_hotspod .box-content_tab h2 {
          font-size: 20px;
          line-height: 110%;
          margin-bottom: 20px; } }
    .popup_hotspod .box-content_tab h3 {
      font-family: 'HelveticaNeueLTStd';
      font-weight: 300;
      font-size: 35px;
      line-height: 55px;
      color: #F8F8F8;
      margin-bottom: 50px; }
      @media (max-width: 1470px) {
        .popup_hotspod .box-content_tab h3 {
          font-size: 30px;
          margin-bottom: 40px; } }
      @media (max-width: 1200px) {
        .popup_hotspod .box-content_tab h3 {
          font-size: 25px;
          text-align: center;
          margin-bottom: 0; } }
      @media (max-width: 768px) {
        .popup_hotspod .box-content_tab h3 {
          font-size: 18px;
          line-height: 110%;
          margin-bottom: 20px; } }
    .popup_hotspod .box-content_tab p {
      font-family: 'HelveticaNeueLTStd';
      font-weight: 300;
      font-size: 16px;
      line-height: 25px;
      color: #B0B0B0;
      width: 100%;
      margin-bottom: 35px; }
      @media (max-width: 1450px) {
        .popup_hotspod .box-content_tab p {
          font-size: 14px;
          width: 80%; } }
      @media (max-width: 1200px) {
        .popup_hotspod .box-content_tab p {
          width: 100%; } }
  .popup_hotspod .content-right {
    width: 38%;
    height: 100%;
    background: transparent;
    transform: translateX(100%);
    animation: slideRight 0.4s forwards;
    position: absolute;
    top: -6%;
    bottom: 14%;
    right: 0;
    overflow: hidden; }
    @media (max-width: 1200px) {
      .popup_hotspod .content-right {
        position: relative;
        width: 100%;
        background: #333333;
        top: auto;
        bottom: auto;
        overflow: hidden;
        animation: none;
        transform: translateX(0%); } }
    .popup_hotspod .content-right .box-img {
      transform: skewX(-32deg);
      transform-origin: left bottom;
      overflow: hidden;
      background: #2B2B2B;
      height: 100%; }
      @media (max-width: 1200px) {
        .popup_hotspod .content-right .box-img {
          transform: skewX(0deg);
          width: 60%;
          margin: 0 auto;
          opacity: 0;
          animation: opacityContent .4s;
          animation-fill-mode: forwards;
          animation-delay: .2s;
          text-align: center; } }
      @media (max-width: 768px) {
        .popup_hotspod .content-right .box-img {
          width: 100%; } }
      .popup_hotspod .content-right .box-img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: skewX(32deg);
        transform-origin: left bottom; }
        @media (max-width: 1200px) {
          .popup_hotspod .content-right .box-img img {
            transform: skewX(0deg);
            object-position: center;
            margin: 0 auto;
            object-fit: contain; } }
  .popup_hotspod .media_iframe {
    width: 80%;
    height: 86%;
    margin: 0 auto;
    box-shadow: -1px 6px 30px 7px rgba(0, 0, 0, 0.7); }
    @media (max-width: 768px) {
      .popup_hotspod .media_iframe {
        box-shadow: none; } }
    .popup_hotspod .media_iframe > img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .popup_hotspod .media_iframe video,
    .popup_hotspod .media_iframe iframe {
      object-fit: cover;
      width: 100%;
      height: 100%; }
      .popup_hotspod .media_iframe video > img,
      .popup_hotspod .media_iframe iframe > img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    @media (max-width: 992px) {
      .popup_hotspod .media_iframe .box-img {
        display: none; } }
  .popup_hotspod ul.tabs {
    margin: 0px;
    padding: 0px;
    list-style: none;
    position: absolute;
    width: 12%;
    margin-left: 2.2%;
    margin-top: 8.5%;
    max-height: 202px;
    height: auto;
    z-index: 2;
    opacity: 0;
    animation: opacityContent .4s;
    animation-fill-mode: forwards; }
    @media (max-width: 1200px) {
      .popup_hotspod ul.tabs {
        width: 100%;
        margin: 0 auto;
        display: block;
        height: auto;
        text-align: center;
        padding-top: 40px;
        position: relative;
        background: #333; } }
    .popup_hotspod ul.tabs:before {
      position: absolute;
      left: 3%;
      width: 1px;
      height: 100%;
      background: #4C4C4C;
      margin: 4px 0px; }
      @media (max-width: 1200px) {
        .popup_hotspod ul.tabs:before {
          content: none; } }
    .popup_hotspod ul.tabs li {
      font-weight: bold;
      font-size: 14px;
      line-height: 100%;
      text-transform: uppercase;
      color: #4C4C4C;
      cursor: pointer;
      display: block;
      margin-bottom: 40px;
      position: relative;
      padding-left: 40px;
      transition: .3s ease; }
      @media (max-width: 1250px) {
        .popup_hotspod ul.tabs li {
          font-size: 11px; } }
      @media (max-width: 1200px) {
        .popup_hotspod ul.tabs li {
          display: inline-block;
          margin-right: 20px;
          margin-bottom: 15px; } }
      .popup_hotspod ul.tabs li:before {
        content: '';
        position: absolute;
        display: block;
        width: 14px;
        height: 10px;
        left: 0;
        top: 0;
        background: #4C4C4C;
        transform: skew(-30deg);
        transition: .3s ease;
        z-index: 1; }
      .popup_hotspod ul.tabs li:hover {
        color: #03A9F5; }
        .popup_hotspod ul.tabs li:hover:before {
          background: #03A9F5; }
      .popup_hotspod ul.tabs li li:last-child {
        margin-bottom: 0; }
      .popup_hotspod ul.tabs li.current {
        color: #03A9F5; }
        .popup_hotspod ul.tabs li.current:before {
          width: 21px;
          height: 14px;
          background: #03A9F5; }
    .popup_hotspod ul.tabs li:last-child {
      margin-bottom: -25px; }
  .popup_hotspod .tab-content.current {
    display: inherit; }
  .popup_hotspod .tab-content.current[data-tab="tab-3"] {
    display: inherit; }
  .popup_hotspod .list-hotspot5 {
    column-count: 2;
    column-gap: 15%; }

.scene {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate3d(0px, 0px, 0px);
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transition: transform 0.75s ease;

  body.is--touch & {
    overflow: auto;
  }

  &__input {
    display: block;
    position: relative;
    pointer-events: none;

    body.is--touch & {
      overflow: hidden;
    }

    img {
      position: absolute;
      width: 140%;
      height: 140%;
      left: -20%;
      top: -20%;

      &[data-scene-bg] {
        position: relative;
      }
    }
  }

  .scene-layers {
    display: inline-block;
    max-width: none;
    transform-style: preserve-3d;
    backface-visibility: hidden;
  }

  .scene-zoom {
    display: block;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    transition: transform 0.75s ease;
  }

  .mask_color {
    clip-path: circle(75px at 0 0);
  }

  .wrap_hotspots {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    pointer-events: none;

    .hotspot {
      pointer-events: all;
      position: absolute;
      cursor: pointer;
      width: 20px;
      height: 20px;
      transition: 1.5s ease;
      border-radius: 50%;

      body.active &:not(.active) {
        opacity: 0;
      }

      span {
        background: #90C241;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        transition: 2s ease;
      }

      &:before {
        content: '';
        width: 40px;
        height: 40px;
        top: -50%;
        left: -50%;
        position: absolute;
        background: rgba(144, 194, 65, 0.25);
        border-radius: 50%;
        z-index: 2;
      }

      &:after {
        content: '';
        width: 60px;
        height: 60px;
        position: absolute;
        top: -100%;
        left: -100%;
        background: rgba(144, 194, 65, 0.25);
        border-radius: 50%;
        z-index: 2;

      }

      &:hover,
      &.is--hover {
        &:before {
          animation: scaleInBefore 1s infinite cubic-bezier(.28, .23, .33, .32);
        }

        &:after {
          animation: scaleInAfter 1s infinite cubic-bezier(.28, .23, .33, .32);
        }
      }

      &.active,
      &.visited {
        span {
          background: #e42d19;
        }

        &:before {
          background: rgba(228, 45, 25, 0.25);
        }

        &:after {
          background: rgba(228, 45, 25, 0.25);
        }
      }

      &.is--hover {
        background: #03a9f5;

        &:before {
          background: rgba(3, 169, 245, 0.25);
        }

        &:after {
          background: rgba(3, 169, 245, 0.25);
        }
      }
    }
  }

}
.texture_top {
  position: absolute;
  top: 1.8%;
  left: 0;
  width: 100%;
  max-width: 30%;
  pointer-events: none;

  svg {
    max-width: 100%;
    width: 100%;
  }
}

.texture_bottom {
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;

  svg {
    max-width: 100%;
    width: 100%;
  }
}


// subtitle
.subtitle_hotspot{
  position: absolute;
  background: #333333;
  top: 4.2%;
  left: 32%;
  max-width: 100%;
  width: auto;
  padding: 1% 3% 1% 2%;
  text-align: left;
  visibility: hidden;
  opacity: 0;
  transform: skewX(-31deg);
  transition: all, .1s ease;
  z-index: 999;
  @media (max-width: 1600px) {
    top: 3.3%;
  }

  @media (max-width: 1200px) {
    top: 90px;
    padding: 5px 15px;
    left: -15px;
  }
  @media (max-width: 992px) {
    top: 75px;
  }
  @media (max-width: 768px) {
    top: 70px;
  }
  @media (max-width: 525px) {
    top: 60px;
  }
  h1{
    padding: 0 0px 0 20px;
    width: 100%;
    font-family: 'HelveticaNeueLTStd';
    font-weight: 800;
    font-style: normal;
    font-size: 110%;
    line-height: 156%;
    text-align: left;
    text-transform: uppercase;
    color: #F8C000;
    z-index: 1;
    pointer-events: none;
    opacity: 0;
    transform: skewX(31deg);
    transition: all, .5s ease;
    @media (max-width: 1400px) {
      font-size: 18px;
    }
    @media (max-width: 1200px) {
      font-size: 12px;
    }
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  &.active{
    visibility: visible;
    opacity: 1;
    transform: skewX(-31deg);
    h1{
      animation: opacityContent .4s;
      animation-fill-mode: forwards;
      animation-delay: .2s;
    }
  }
}

.hotspot1 {
  top: 32%;
  left: 20.2%;
  transform: scale(.67);
}

.hotspot2 {
  top: 33%;
  left: 23.2%;
  transform: scale(.67);
}

.hotspot3 {
  top: 27%;
  left: 20.2%;
  transform: scale(.67);
}

.hotspot4 {
  top: 27.5%;
  left: 22.2%;
  transform: scale(.67);
}

.hotspot5 {
  top: 27%;
  left: 16.4%;
  transform: scale(.67);
}

.hotspot6 {
  top: 22%;
  left: 20.1%;
  transform: scale(.67);
}

.hotspot7 {
  top: 18%;
  left: 20.1%;
  transform: scale(.67);
}

.hotspot8 {
  top: 25%;
  left: 26.4%;
  transform: scale(.67);
}

.hotspot9 {
  bottom: 9%;
  left: 18.8%;
  transform: scale(.67);
}

.hotspot10 {
  bottom: 9%;
  left: 20.8%;
  transform: scale(.67);
}

.hotspot11 {
  bottom: 11%;
  left: 20%;
  transform: scale(.67);
}

.hotspot12 {
  bottom: 16.5%;
  left: 20%;
  transform: scale(.67);
}

.hotspot13 {
  bottom: 19.3%;
  left: 16.7%;
  transform: scale(.67);
}

.hotspot14 {
  bottom: 17%;
  left: 18.8%;
  transform: scale(.67);
}

.hotspot15 {
  bottom: 22.2%;
  left: 15.4%;
}

.hotspot16 {
  bottom: 17%;
  left: 20.8%;
  transform: scale(.67);
}

.hotspot17 {
  top: 23%;
  left: 46%;
}

.hotspot18 {
  top: 28%;
  left: 44.8%;
  -webkit-transform: scale(0.67);
  transform: scale(0.67);
}

.hotspot19 {
  top: 29.2%;
  left: 45.7%;
}

.hotspot20 {
  top: 26%;
  left: 41%;
  -webkit-transform: scale(0.67);
  transform: scale(0.67);
}
.hotspot21 {
  top: 24%;
  left: 41.5%;
  -webkit-transform: scale(0.67);
  transform: scale(0.67);
}
.hotspot22 {
  top: 28.2%;
  right: 31.7%;
  -webkit-transform: scale(0.67);
  transform: scale(0.67);
}

.hotspot23 {
  bottom: 25.7%;
  right: 37.7%;
}
.hotspot24 {
  top: 28%;
  right: 12%;
}
.hotspot25 {
  bottom: 10.7%;
  right: 35.3%;
}
.hotspot26 {
  bottom: 39%;
  right: 13.7%;
  -webkit-transform: scale(0.67);
  transform: scale(0.67);
}

/*******TOOLTIP*********/
.wrap_tooptip{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: 0!important;
  backface-visibility: hidden;
  visibility: hidden;
  opacity: .40;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  &.fade{
    visibility: visible;
    opacity: 1;
    transition: .3s ease-in;
  }
  .t_container {
    position: absolute;
    width: 50vw;
    height: 44vh;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    //overflow: hidden;
    @media (max-width: 1200px) {
      width: 700px;
      max-width: 90%;
      height: 68vh;
      overflow: hidden;
      bottom: 14vh!important;
      margin: 0 auto;
      left: 0!important;
      right: 0!important;
      top: auto!important;
    }
    @media (max-width: 768px) {
      bottom: 9vh;
    }
    &:before{
      content: '';
      display: inline-block;
    }
  }
  .t_content{
    position: relative;
    display: block;
    margin: 0 auto;
    text-align: left;
    z-index: 1045;

    .close{
      position: absolute;
      right: -1.6%;
      top: -7%;
      width: 4%;
      height: 8%;
      color: #FFFFFF;
      background: #000;
      z-index: 3;
      display: block;
      cursor: pointer;
      @media (max-width:1200px) {
        top: 10px;
        right: 10px;
        width: 40px;
        height: 20px;
      }
      &:hover{
        opacity: .9;
      }
      &:before {
        transition: all .3s ease;
        top: 50%;
        left: 26%;
        position: absolute;
        border: 0;
        height: 2px;
        width: 47%;
        content: '';
        background: #fff;
        transform: rotate(-45deg);
        display: block;
      }

      &:after {
        content: '';
        transition: all .3s ease;
        top: 50%;
        left: 26%;
        position: absolute;
        border: 0;
        height: 2px;
        width: 47%;
        background: #fff;
        transform: rotate(45deg);
        display: block;
      }
    }
  }
}
.tooltip_hotspod {
  //max-width: 1071px;
  width: 100%;
  max-height: 528px;
  height: 100%;
  margin: 0 auto;
  box-shadow: 0px 0px 20px rgba(1, 1, 1, 0.15);
  position: relative;

  .box_overfloy{
    max-width: 1071px;
    width: 100%;
    max-height: 528px;
    height: 100%;
    margin: 0 auto;
    background: #000000;
    @media (max-width: 1200px) {
      overflow: hidden;
    }
    span.figure {
      background-color: black;
      display: inline-block;
      height: 40px;
      position: absolute;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      width: 40px;
      position: absolute;
      z-index: 2;
      top: -3%;
      @media (max-width: 1200px) {
        display: none;

      }
    }

  }

  .content {
    display: flex;
    position: relative;
    max-height: 100%;
    z-index: 2;

    @media (max-width: 992px) {
      flex-direction: column;
      display: block;
      overflow-y: scroll;
      overflow-x: hidden;
      width: 100%;
      max-height: 67vh;
    }
  }

  .description {
    width: 45%;
    color: white;
    margin: 50px 0px 5px 80px;
    padding-right: 10px;
    position: relative;
    z-index: 2;
    height: 40vh;
    max-height: 100%;
    overflow-y: auto;
    display: block;
    scrollbar-color: #333333 #4C4C4C;
    scrollbar-width: thin;
    @media (max-width: 1450px) {
      margin: 30px 20px 5px 40px;
    }
    @media (max-width: 992px) {
      width: 65%;
    }
    @media (max-width: 992px) {
      width: auto;
      margin: 20px;
      height: auto;
      overflow: initial;
    }
    @media (max-width: 992px) {
      margin: 20px 20px 5px 20px;
    }



    h2 {
      font-family: 'HelveticaNeueLTStd';
      font-style: normal;
      font-weight: 800;
      font-size: 26px;
      line-height: 156%;
      text-transform: capitalize;
      margin-bottom: 35px;
      @media (max-width: 1450px) {
        font-size: 22px;
        margin-bottom: 10px;
      }
      @media (max-width: 1200px) {
        text-align: center;
      }

      @media (max-width: 992px) {
        margin-bottom: 20px;
        font-size: 16px;
      }
    }

    p,
    li {
      font-family: 'HelveticaNeueLTStd';
      font-weight: 300;
      font-size: 16px;
      line-height: 25px;
      color: #FFFFFF;
      @media (max-width: 992px) {
        font-size: 14px;
        line-height: 17px;
      }
    }

    p {
      margin-bottom: 23px;
      @media (max-width: 1450px) {
        margin-bottom: 10px;
      }

      @media (max-width: 992px) {
        margin-bottom: 20px;
      }
    }

    ul {
      li {
        margin-bottom: 35px;
        display: block;
        position: relative;
        padding-left: 35px;
        @media (max-width: 1450px) {
          margin-bottom: 10px;
        }

        &:before {
          content: '';
          position: absolute;
          display: block;
          width: 17px;
          height: 8px;
          left: 3px;
          top: 5px;
          background: #90C241;
          transform: skew(-30deg);
        }
      }
    }
  }

  .photo {
    width: 55%;
    background: transparent;
    position: relative;
    background: black;
    @media (max-width: 1200px) {
      width: 45%;
      padding-top: 40px;
    }
    @media (max-width: 992px) {
      margin: 0 auto;
    }
    @media (max-width: 768px) {
      width: 100%;
    }

    .mask {
      background: transparent;
      position: relative;
      transform-origin: left bottom;
      transform: skewX(-31deg);
      overflow: hidden;
      height: 36vh;
      max-height: 100%;
      top: -7%;
      right: -3%;
      z-index: 2;
      @media (max-width: 1200px) {
        height: auto;
        top: auto;
        right: auto;
        overflow: visible;
        padding: 0 20px;
      }
    }

    img {
      width: 100%;
      display: block;
      height: 100%;
      object-fit: cover;
      object-position: right;
      position: relative;
      -webkit-transform-origin: left bottom;
      transform-origin: left bottom;
      -webkit-transform: skewX(31deg);
      transform: skewX(31deg);
      background: white;
      @media (max-width: 1200px) {
        width: 100%;
        margin: auto;
        height: 100%;
        -o-object-fit: contain;
        object-fit: contain;
        -o-object-position: center;
        object-position: left;
      }
    }
  }

  .links {
    width: 93%;
    display: block;
    text-align: right;
    padding-bottom: 20px;
    padding-top: 20px;
    padding-right: 30px;
    margin-right: 30px;
    text-transform: uppercase;
    @media (max-width: 1200px) {
      text-align: center;
      width: 100%;
      height: 60px;
    }

    @media (max-width: 768px) {
      padding-top: 20px;
    }

    .btn_link {
      font-family: 'HelveticaNeueLTStd';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 25px;
      text-align: center;

      background: transparent;
      transition: 0.25s;
      border: 2px solid #3CAAF8;
      padding: 8.5px 46.5px;
      box-shadow: inset 0 0 0 2em #3CAAF8;
      color: #fff;

      &:hover,
      &:focus {
        color: #3CAAF8;
        box-shadow: none;
      }
    }
  }
}


/**********POPAP*********/
.popup_hotspod {
  width: 100%;
  top: 10%;
  height: 100%;
  background: transparent;
  position: relative;
  // display: none;
  @media (max-width: 1200px) {
    top: 0vh;
    width: 90%;
    margin:  0 auto;
  }

  .box-content {
    position: relative;
    max-height: 100%;
    height: 65vh;

    @media (max-width: 1200px) {
      overflow: hidden;
      height: 68vh;
    }

    svg{
      position: absolute;
      z-index: 2;
      width: 29%;
      height: 35%;
      left: -25px;
      @media (max-width: 1200px) {
        z-index: 3;
      }
    }
  }

  .tab-content {
    display: none;
    background: transparent;
    width: 100%;
    height: 70vh;
    overflow: hidden;
    color: #ffffff;

    @media (max-width: 1200px) {
      overflow-y: scroll;
      height: 100%;
    }
  }

  .content-left {
    width: 100%;
    height: 100%;
    transform: translateX(0);
    animation: slideLeft 0.4s;
    display: flex;
    position: relative;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      animation: none;
    }

    @media (max-width: 1200px) {
      overflow: hidden;
      height: auto;
    }

    @media (max-width: 768px) {
      animation: none;
    }
  }

  .box-content_tab {
    text-align: left;
    position: relative;
    max-height: 100%;
    width: 100%;
    transform-origin: right top;
    transform: skewX(-31deg);
    background: #333333;
    padding-left: 15%;

    &:before{
      content: '';
      transform-origin: right top;
      transform: skewX(31deg);
      position: relative;
      background-image: url('assets/dist/svg/ep.svg');
      background-size: inherit;
      background-repeat: no-repeat;
      background-size: 28rem;
      background-repeat: no-repeat;
      background-position: 2% 0%;
      max-width: 520px;
      max-height: 135px;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: -7px;
    }

    @media (max-width: 1200px) {
      padding-left: 17%;
      transform: skewX(0deg);
      padding: 20px;
    }

    .text {
      transform-origin: right top;
      transform: skewX(31deg);
      position: relative;
      width: 50%;
      max-height: 53vh;
      height: 100%;
      margin: 5% 2%;
      opacity: 0;
      animation: opacityContent .4s;
      animation-fill-mode: forwards;
      animation-delay: .2s;
      overflow-y: auto;
      position: relative;
      z-index: 2;
      scrollbar-color: #333333 #4C4C4C;
      scrollbar-width: thin;
      @media (max-width: 1400px) {
        margin: 5% 6%;
      }

      @media (max-width: 1200px) {
        width: 100%;
        height: auto;
        transform: skewX(0deg);
        padding: 0;
        max-height: 100%;
        margin: 0;
      }
      a{
        display: block;
        font-size: 18px;
        margin-bottom: 25px;
        color: #B0B0B0;
        text-decoration: underline;
        text-decoration-color:  #F8C000;
        padding-bottom: 4px;
        &:hover{
          text-decoration: none;
        }
      }
      ul {
        li {
          margin-bottom: 20px;
          display: block;
          position: relative;
          padding-left: 35px;
          font-family: 'HelveticaNeueLTStd';
          font-weight: 300;
          font-size: 16px;
          line-height: 25px;
          color: #B0B0B0;
          @media (max-width: 1450px) {
            margin-bottom: 10px;
          }

          &:before {
            content: '';
            position: absolute;
            display: block;
            width: 17px;
            height: 8px;
            left: 3px;
            top: 5px;
            background: #F8C000;
            transform: skew(-30deg);
          }
        }
      }
    }

    h2 {
      font-family: 'HelveticaNeueLTStd';
      font-weight: 800;
      font-size: 51px;
      line-height: 110%;
      text-align: left;
      text-transform: capitalize;
      color: #FFFFFF;
      @media (max-width: 1600px) {
        font-size: 45px;
      }

      @media (max-width: 1470px) {
        font-size: 45px;
        line-height: 120%;
      }

      @media (max-width: 1200px) {
        font-size: 40px;
        line-height: 110%;
        text-align: center;
      }

      @media (max-width: 768px) {
        font-size: 20px;
        line-height: 110%;
        margin-bottom: 20px;
      }


    }

    h3 {
      font-family: 'HelveticaNeueLTStd';
      font-weight: 300;
      font-size: 35px;
      line-height: 55px;
      color: #F8F8F8;
      margin-bottom: 50px;

      @media (max-width: 1470px) {
        font-size: 30px;
        margin-bottom: 40px;
      }

      @media (max-width: 1200px) {
        font-size: 25px;
        text-align: center;
        margin-bottom: 0;
      }

      @media (max-width: 768px) {
        font-size: 18px;
        line-height: 110%;
        margin-bottom: 20px;
      }
    }

    p {
      font-family: 'HelveticaNeueLTStd';
      font-weight: 300;
      font-size: 16px;
      line-height: 25px;
      color: #B0B0B0;
      width: 100%;
      margin-bottom: 35px;

      @media (max-width: 1450px) {
        font-size: 14px;
        width: 80%;
      }

      @media (max-width: 1200px) {
        width: 100%;
      }
    }
  }

  .content-right {
    width: 38%;
    height: 100%;
    background: transparent;
    transform: translateX(100%);
    animation: slideRight 0.4s forwards;
    position: absolute;
    top: -6%;
    bottom: 14%;
    right: 0;
    overflow: hidden;

    @media (max-width: 1200px) {
      position: relative;
      width: 100%;
      background: #333333;
      top: auto;
      bottom: auto;
      overflow: hidden;
      animation: none;
      transform: translateX(0%);
    }


    .box-img {
      transform: skewX(-32deg);
      transform-origin: left bottom;
      overflow: hidden;
      background: #2B2B2B;
      height: 100%;

      @media (max-width: 1200px) {
        transform: skewX(0deg);
        width: 60%;
        margin: 0 auto;
        opacity: 0;
        animation: opacityContent .4s;
        animation-fill-mode: forwards;
        animation-delay: .2s;
        text-align: center;
      }
      @media (max-width: 768px) {
        width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: skewX(32deg);
        transform-origin: left bottom;

        @media (max-width: 1200px) {
          transform: skewX(0deg);
          object-position: center;
          margin: 0 auto;
          object-fit: contain;
        }
      }
    }
  }

  .media_iframe{
    width: 80%;
    height: 86%;
    margin: 0 auto;
    box-shadow: -1px 6px 30px 7px rgba(0,0,0,.7);
    @media (max-width: 768px) {
      box-shadow: none;
    }
    >img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    video,
    iframe {
      object-fit: cover;
      width: 100%;
      height: 100%;

      >img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .box-img {
      @media (max-width: 992px) {
        display: none;
      }
    }
  }

  ul.tabs {
    margin: 0px;
    padding: 0px;
    list-style: none;
    position: absolute;
    width: 12%;
    margin-left: 2.2%;
    margin-top: 8.5%;
    max-height: 202px;
    height: auto;
    z-index: 2;
    opacity: 0;
    animation: opacityContent .4s;
    animation-fill-mode: forwards;

    @media (max-width: 1200px) {
      width: 100%;
      margin: 0 auto;
      display: block;
      height: auto;
      text-align: center;
      padding-top: 40px;
      position: relative;
      background: #333;
    }


    &:before {
      position: absolute;
      left: 3%;
      width: 1px;
      height: 100%;
      background: #4C4C4C;
      margin: 4px 0px;

      @media (max-width: 1200px) {
        content: none;
      }
    }

    li {
      font-weight: bold;
      font-size: 14px;
      line-height: 100%;
      text-transform: uppercase;
      color: #4C4C4C;
      cursor: pointer;
      display: block;
      margin-bottom: 40px;
      position: relative;
      padding-left: 40px;
      transition: .3s ease;

      @media (max-width: 1250px) {
        font-size: 11px;
      }

      @media (max-width: 1200px) {
        display: inline-block;
        margin-right: 20px;
        margin-bottom: 15px;
      }

      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 14px;
        height: 10px;
        left: 0;
        top: 0;
        background: #4C4C4C;
        transform: skew(-30deg);
        transition: .3s ease;
        z-index: 1;
      }


      &:hover {
        color: #03A9F5;

        &:before {
          background: #03A9F5;
        }
      }

      li:last-child {
        margin-bottom: 0;
      }

      &.current {
        color: #03A9F5;

        &:before {
          width: 21px;
          height: 14px;
          background: #03A9F5;
        }
      }
    }
    li:last-child{
      margin-bottom: -25px;
    }

  }



  .tab-content.current {
    display: inherit;
  }

  .tab-content.current[data-tab="tab-3"] {
    display: inherit;
  }
  .list-hotspot5{
    column-count: 2;
    column-gap: 15%;
  }
}

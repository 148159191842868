.main-head {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: transparent;
  height: 12%;
  z-index: 5000;
  pointer-events: none;

  div.black {
    max-width: 30.5%;
    width: 100%;
    height: 13%;
    background: transparent;
    position: relative;

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background: #000000;
      bottom: 0;
      transform-origin: right top;
      transform: skewX(-37deg);
    }
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 9.5%;
    left: 0;
    right: 0;
  }

  &__logo {
    width: 31%;
    background: transparent;
    position: relative;
    padding: 1.5% 0% 1.5% 2.5%;
    pointer-events: all;
    @media (max-width: 768px) {
      width: 43%;
      padding: 0;
      padding-left: 15px;
    }
    @media (max-width: 470px) {
      padding-left: 5px;
    }
    @media (max-width: 325px) {
      width: 47%;
    }

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      background: #E42D19;
      bottom: 0;
      transform-origin: right top;
      transform: skewX(-30deg);
      box-shadow: 0px 0px 20px rgba(1, 1, 1, 0.15);
      @media (max-width: 768px) {
        height: 93%;
        top: 0;
      }
      @media (max-width: 525px) {
        height: 73%;

      }
    }

    a {
      display: flex;
      width: 100%;
      position: relative;
      z-index: 1;
      align-items: center;
      font-family: 'BebasNeueBold';
      font-style: normal;
      font-weight: bold;
      text-transform: uppercase;
      color: #FFFFFF;
      font-size: 50px;
      line-height: 100%;
      padding-top: 5px;
      @media (max-width: 1700px) {
        font-size: 42px;
      }
      @media (max-width: 1400px) {
        font-size: 34px;
      }
      @media (max-width: 1150px) {
        font-size: 30px;
      }
      @media (max-width: 992px) {
        font-size: 26px;
      }
      @media (max-width: 860px) {
        font-size: 23px;
        padding-top: 10px;
      }
      @media (max-width: 768px) {
        padding-top: 18px;
      }
      @media (max-width: 630px) {
        padding-top: 18px;
        font-size: 20px;
      }
      @media (max-width: 545px) {
        height: auto;
        font-size: 17px;
        padding-top: 22px;
      }
      @media (max-width: 525px) {
        font-size: 17px;
        padding-top: 15px;
      }
      @media (max-width: 470px) {
        font-size: 13px;
        padding-top: 16px;
      }
      @media (max-width: 360px) {
        font-size: 11px;
        padding-top: 20px;
      }
    }

    //a {
    //  display: block;
    //  max-width: 64.7%;
    //  width: 100%;
    //  position: relative;
    //  z-index: 1;
    //  display: flex;
    //  align-items: center;
    //  height: 100%;
    //  @media (max-width: 525px) {
    //    height: auto;
    //    padding-top: 10px!important;
    //  }
    //  span{
    //    font-family: 'BebasNeueBold';
    //    font-style: normal;
    //    font-weight: bold;
    //    text-transform: uppercase;
    //    color: #FFFFFF;
    //    display: block;
    //  }
    //  span:first-child{
    //    font-size: 88px;
    //    height: 12px;
    //    @media (max-width: 1400px) {
    //      font-size: 70px;
    //      height: 15px;
    //    }
    //    @media (max-width: 1280px) {
    //      font-size: 60px;
    //      height: 15px;
    //    }
    //    @media (max-width: 992px) {
    //      font-size: 52px;
    //      height: 20px;
    //    }
    //    @media (max-width: 580px) {
    //      font-size: 39px;
    //    }
    //    @media (max-width: 525px) {
    //      font-size: 28px;
    //      height: auto;
    //    }
    //    @media (max-width: 375px) {
    //      font-size: 25px;
    //      line-height: 120%;
    //      height: 25px;
    //    }
    //  }
    //  span:last-child{
    //    font-size: 30px;
    //    margin-left: 13px;
    //    letter-spacing: 1px;
    //    line-height: 97%;
    //    @media (max-width: 1400px) {
    //      font-size: 27px;
    //    }
    //    @media (max-width: 1280px) {
    //      font-size: 22px;
    //    }
    //    @media (max-width: 992px) {
    //      font-size: 18px;
    //      margin-left: 7px;
    //    }
    //    @media (max-width: 580px) {
    //      font-size: 16px;
    //    }
    //    @media (max-width: 525px) {
    //      font-size: 12px;
    //    }
    //    @media (max-width: 375px) {
    //      font-size: 11px;
    //    }
    //  }
    //  @media (max-width: 768px) {
    //    max-width: 73%;
    //    padding: 0px 15px;
    //    padding-left: 10%;
    //  }
    //  @media (max-width: 375px) {
    //    padding-left: 5px;
    //  }
    //}

    img {
      width: 100%;
    }
  }

  &__info {
    width: 69%;
  }

  &__help,
  &__menu {
    width: 10%;
    max-height: 74.1%;
    height: 100%;
    float: right;
    position: relative;
    color: #FFFFFF;
    pointer-events: all;
    @media (max-width: 1200px) {
      width: 70px;
      height: 60px;
    }
  }

  .white_row {
    background: white;
    float: right;
    text-align: center;
    position: relative;
    right: 3.1%;
    width: 1px;
    top: 10%;
    max-height: 74.1%;
    height: 50%;
    transform: skew(-36deg, 0deg);
    @media (max-width: 1200px) {
      display: none;
    }
  }

  &__help {
    position: relative;
    right: 1.5%;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #000000;
      transform-origin: right top;
      transform: skewX(-36deg);
      box-shadow: 0px 0px 20px rgba(1, 1, 1, 0.15);
      transition: all, .7s ease-in-out;
    }

    &:hover {
      &:after {
        background: #333333;
      }
    }

    > a{
      position: relative;
      z-index: 1;
      padding-top: 20%;
      padding-left: 15%;
      cursor: pointer;
      width: 45%;
      display: block;
      @media (max-width: 1500px) {
        padding-top: 15%;
        padding-left: 11%;
      }
      @media (max-width: 1200px) {
        padding-top: 15%;
      }
    }

    img {
      width: auto;
      height: auto;
      object-fit: contain;
      display: block;
      @media (max-width: 1200px) {
        width: 12px;
      }
    }

    span {
      display: block;
      left: -5px;
      padding-top: 2px;
      position: relative;
      font-family: 'HelveticaNeueLTStd';
      font-weight: 300;
      font-size: 70%;
      line-height: 17px;
      text-transform: uppercase;
      @media (max-width: 1200px) {
        font-size: 60%;
        bottom: 3px;
      }
    }
  }

  &__menu {
    position: relative;
    right: 0;
    transition: all, .3s ease-in-out;
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #000000;
      transform-origin: right top;
      transform: skewX(-36deg);
      box-shadow: 0px 0px 20px rgba(1, 1, 1, 0.15);
      transition: all, .3s ease-in-out;
    }

    &.active {
      &:after {
        background: #333333;
      }
    }

    &:hover {
      &:after {
        background: #333333;
      }
    }

    >div {
      //position: relative;
      //z-index: 1;
      //cursor: pointer;
      //width: 40.5%;
      //height: 73%;
      position: relative;
      z-index: 1;
      padding-top: 20%;
      padding-left: 15%;
      cursor: pointer;
      width: 45%;
      display: block;
      @media (max-width: 1500px) {
        padding-top: 15%;
        padding-left: 11%;
      }
      @media (max-width: 1200px) {
        padding-top: 9%;
      }
    }

    .btn_menu {
      cursor: pointer;
      position: relative;
      height: 17px;

      &:before {
        -webkit-transition: all .3s ease;
        -ms-transition: all .3s ease;
        transition: all .3s ease;
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        height: 2px;
        width: 48%;
        background: #FFFFFF;
        content: '';
      }

      &:after {
        position: absolute;
        bottom: 6px;
        left: 0;
        display: block;
        height: 4px;
        width: 48%;
        border-top: 2px solid #FFFFFF;
        border-bottom: 2px solid #FFFFFF;
        content: '';
        @media (max-width: 1200px) {
          height: 2px;
          bottom: 4px;
        }
      }
    }

    #btn_menu_wpap.opened .btn_menu {
      &:before {
        bottom: 8px;
        background: #fff;
        width: 48%;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        @media (max-width: 1200px) {
          bottom: 6px;
        }
      }

      &:after {
        -webkit-transition: all .3s ease;
        -ms-transition: all .3s ease;
        transition: all .3s ease;
        bottom: 8px;
        border: 0;
        height: 2px;
        width: 48%;
        background: #fff;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        @media (max-width: 1200px) {
          bottom: 6px;
        }
      }

    }

    span {
      display: block;
      left: -5%;
      padding-top: 2px;
      position: relative;
      font-family: 'HelveticaNeueLTStd';
      font-weight: 300;
      font-size: 70%;
      line-height: 17px;
      text-transform: uppercase;

      @media (max-width: 1700px) {
        left: -8%;
      }
      @media (max-width: 1500px) {
        left: -13%;
      }
      @media (max-width: 1400px) {
        left: -15%;
      }
      @media (max-width: 1200px) {
        font-size: 60%;
        bottom: 3px;
      }
    }
  }
}

.navigation {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 11.5%;
  background: #333333;
  width: 17%;
  overflow-y: auto;
  padding: 29px 37px 10px 33px;
  transform: translateX(100%);
  transition: all, .2s ease;
  z-index: 99;
  @media (max-width: 1200px) {
    width: 250px;
    top: 58px;
  }

  &.opened {
    right: 8px;
    transform: translateX(0%);
    transition: all, .2s ease;
    @media (max-width: 768px) {
     right: 0;
    }
  }
}

.my-navigation {
  ul {
    li {
      display: inline-block;

      span,
      a {
        font-family: 'HelveticaNeueLTStd';
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        color: #F8C000;
        margin-bottom: 12%;
        display: block;
        @media (max-width: 768px) {
          margin-bottom: 15px;
        }
      }

      ul {
        transition: .3s ease-in;
        li {
          margin-bottom: 15%;
          transition: .3s ease-in;
          position: relative;
          &:before{
            transition: .3s ease-in;
            position: absolute;
            display: block;
            width: 17px;
            height: 8px;
            left: -25px;
            top: 29%;
            content: '';
            background: transparent;
            transform: skew(-30deg);
          }
          @media (max-width: 1400px) {
            margin-bottom: 9%;
          }
          @media (max-width: 768px) {
            margin-bottom: 15px;
          }
          a {
            color: #FFFFFF;
            text-transform: none;
            margin-bottom: 0;
            transition: .3s ease-in;
          }

          &:hover {
            &:before{
              transition: .3s ease-in;
              background: #03A9F5;
            }
            a {
              color: #03A9F5;
            }
          }
        }
      }
    }
  }
}

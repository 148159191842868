html, body {
  margin: 0;
  position: relative;
  height: 100%;
  overflow: hidden;

  &.active{
    @media (max-width: 1200px) {
      overflow: hidden;
    }
  }
}

body{
	position: relative;
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 25px;
  font-family: 'HelveticaNeueLTStd';
  height: 100%;
  margin: 0;

  scrollbar-face-color:#333333;
  scrollbar-arrow-color: red;
  scrollbar-track-color:#4C4C4C;
  scrollbar-shadow-color:rgb(0,0,0);
  scrollbar-highlight-color:rgb(0,0,0);
  scrollbar-3dlight-color:#333333;
  scrollbar-darkshadow-Color:#333333;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
	width: 8px;
	background: #333333;
}

::-webkit-scrollbar-thumb {
	width: 8px;
	height: 20px;
	background: #4C4C4C;
	border-radius: 47px;
}

.scr_zoom{
	position: fixed;
	left: -3%;
	right: 0;
	bottom: -77px;
	width: 162px;
	height: 162px;
	z-index: 5001;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	margin: 0 auto;
  text-align: center;

  @media (max-width: 768px) {
    width: 120px;
    height: 120px;
    bottom: -55px;
    left: 0;
  }
	&:after{
		content: '';
		background: #03A9F5;
		width: 134px;
		height: 134px;
		position: absolute;
		top: 17px;
		left: 15px;
		right: 0;
		bottom: 0;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
    @media (max-width: 768px) {
      background: #03A9F5;
      width: 110px;
      height: 110px;
      position: absolute;
      top: 17px;
      left: 5px;
    }
   // animation: shadow-pulse 1s infinite;
	}
  > div{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    padding-top: 15%;
    z-index: 1;
  }
	p{
		color: white;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
		position: relative;
		text-align: center;
    text-transform: uppercase;
    font-family: 'HelveticaNeueLTStd';
    font-weight: 300;
    padding-left: 2px;
    @media (max-width: 768px) {
      font-size: 11px;
      line-height: 13px;
    }
	}
  img{
    position: relative;
    margin: 0 auto;
    margin-bottom: 3px;
    width: 20px;
    height: 28px;
    @media (max-width: 768px) {
      height: 21px;
      padding-top: 5px;
    }

  }
}

@keyframes scaleInBefore {
  from {
    transform: scale(.3, .3);

  }
  to {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
  75% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
@keyframes scaleInAfter {
  from {
    transform: scale(1, 1);

  }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0;
  }
  50% {
    transform: scale(1.5, 1.5);
    opacity: 0;
  }
}

@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(3,169,245,.3);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(3,169,245,0);
  }
}
@keyframes slideLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes slideRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes opacityContent {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mfp-close-btn-in .mfp-close {
  color: #fff!important;
  background: black;
  opacity: 1;
  top: -6%;
  @media (max-width: 1200px) {
    top: 0;
  }
}
body .mfp-close:active{
  top: -6%;
  @media (max-width: 1200px) {
    top: 0;
  }
}
.mfp-bg{
  opacity: 0!important;
}
.mfp-zoom-in.mfp-ready.mfp-bg{
  opacity: 0!important;
}
.mfp-close-btn-in .mfp-close{
  color: #02a9f5;
}

.mfp-zoom-in {

  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
  }

  &.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {

    .mfp-with-anim {
      transform: scale(0.8);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }

  }

}
body .mfp-container{
  padding: 0;
}

@keyframes odsoky {
  0%{
    transform: translateY(6px);
  }

  100%{
    transform: translateY(1px);
  }

}

.button {
  background-color: #03A9F5;
  display: inline-block;
  padding: 0.7em 1.8em;
  line-height: 1;
  white-space: nowrap;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  transition: background-color 0.3s;
  box-sizing: border-box;
  text-align: center;

  &:hover {
    background-color: darken($color: #03A9F5, $amount: 10);
  }
}

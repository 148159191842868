.video-popup {
  position: absolute;
  display: none;
  z-index: 5000;
  bottom: -3.8em;
  left: 0;
  width: calc(50% - 1.6em);
  color: #fff;
  padding: 0;
  box-sizing: border-box;
  pointer-events: all;
  max-height: calc(100vh - 140px - 100%);
  transform: translate(-100%, 100%);
  transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  max-width: 940px;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  &.is--initialized {
    display: block;
  }

  &--is-open {
    transform: translate(0, 100%);
    .slider-video h2 {
      opacity: 1!important;
      transition: opacity 0.45s 0.95s;
    }

    @media screen and (max-width: 1024px) {
      & + .details {
        transform: translate(100%, 100%);
      }
    }

    .details__close {
      opacity: 1;
      transition: opacity 0.45s 1.15s;
    }
  }

  &__content {
    padding-bottom: 56.2%;
    position: relative;

    video, iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
    }
    .slider-video{
      position: relative;
      video, iframe {
        background: #000;
      }
      .slick-slide{
        .wrap{
          position: relative;
          padding-bottom: 56.20%;
          height: 0;
          overflow: hidden;
          margin-bottom: 10px;
          @media screen and (max-width: 800px) {
            padding-bottom: 56.25%;
          }
        }
        .wrap-video{
          position: relative;
          overflow: hidden;
          padding-bottom: 1px;
        }
      }
      video{
        position: relative;
        top: auto;
        left: auto;
        width: 100%;
        height: auto;
        max-height: 100%;
      }
      iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-width: 0;
        outline-width: 0;

      }
      h2{
        font-family: 'HelveticaNeueLTStd';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 25px;
        text-align: center;
        color: #FFFFFF;
        background: #000000;
        margin-left: 13%;
        margin-right: 16%;
        height: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        position: relative;
        top: -1px;
        @media screen and (max-width: 1200px) {
          font-size: 14px;
        }
        @media screen and (max-width: 1024px) {
          margin: 0% 6%;
        }
        @media screen and (max-width: 800px) {
          margin-left: 9%;
          margin-right: 9%;
          height: 23px;
        }
        @media screen and (max-width: 340px) {
          font-size: 11px;
        }

      }
      .slick-arrow{
        position: absolute;
        bottom: 0;
        transform-origin: right top;
        -webkit-transform: skewX(-36deg);
        transform: skewX(-36deg);
        border: none;
        z-index: 2;
        font-size: 0;
        line-height: 0;
        width: 86px;
        height: 40px;
        background: #03A9F5;
        @media screen and (max-width: 800px) {
          width: 46px;
          height: 25px;
        }
      }
      .slick-next{
        right: 8%;
        @media screen and (max-width: 1024px) {
          right: 0%;
        }
        @media screen and (max-width: 800px) {
          right: 4%;
        }
        &:before{
          content: '';
          background-image: url("../img/row.png");
          background-size: auto;
          background-repeat: no-repeat;
          background-position: center;
          -webkit-transform: skewX(36deg);
          transform: skewX(36deg) rotate(180deg);
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          position: absolute;
        }
      }

      .slick-prev{
        left: 12%;
        @media screen and (max-width: 1024px) {
          left: 0%;
        }
        @media screen and (max-width: 800px) {
          left: 8%;
        }
        &:before{
          content: '';
          background-image: url("../img/row.png");
          background-size: auto;
          background-repeat: no-repeat;
          background-position: center;
          -webkit-transform: skewX(36deg);
          transform: skewX(36deg);
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          position: absolute;
        }
      }
    }
  }

  .details__close {
    top: 0;
  }
}

.details {
  position: absolute;
  display: none;
  z-index: 5000;
  background: #000;
  bottom: -3.8em;
  right: 0;
  width: calc(50% - 1.6em);
  color: #fff;
  padding: 0;
  box-sizing: border-box;
  pointer-events: all;
  max-height: calc(100vh - 140px - 100%);
  transform: translate(100%, 100%);
  transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  max-width: 940px;


  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  @media screen and (min-width: 1441px) {
    padding: 4.2em;
  }

  &.is--initialized {
    display: flex;
  }

  &--is-open {
    transform: translate(0, 100%);

    @media screen and (min-width: 1441px) {
      .details__header {
        transform: translateX(0) skewX(-35deg);
        transition: transform 0.45s 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
      }
    }

    .details__content {
      opacity: 1;
      transition: opacity 0.45s 0.95s;
    }

    .details__close {
      opacity: 1;
      transition: opacity 0.45s 1.15s;
    }

    .details__footer {
      opacity: 1;
      transition: opacity 0.45s 1.25s;
    }
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 4999;

    body.active & {
      pointer-events: all;
    }
  }

  &__inner {
    width: 100%;
    overflow: auto;

    @media screen and (min-width: 1025px) {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    background: #000;
    border: none;
    outline: none;
    display: block;
    width: 3.1em;
    height: 3.1em;
    padding: 0;
    opacity: 0;
    transition: opacity 0.55s;

    @media screen and (min-width: 1441px) {
      top: -3.2em;
    }

    svg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &__content {
    flex: 1;
    padding: 2em 2em 3em;
    opacity: 0;
    transition: opacity 0.55s;

    @media screen and (min-width: 1025px) {
      max-height: 360px;
      overflow: auto;
    }

    @media screen and (min-width: 1441px) {
      max-width: 50%;
      padding: 0 0.5em 3em 0;
    }

    &::-webkit-scrollbar-track
    {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #333;
    }

    &::-webkit-scrollbar
    {
      width: 3px;
      background-color: #000;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #E42D19;
      border-radius: 0;
    }
    a.link{
      color: #F8C000;
      display: inline-block;
      line-height: 100%;
      white-space: nowrap;
      letter-spacing: 0.3px;
      transition: all, 0.3s;
      box-sizing: border-box;
      text-align: center;
      margin-top: 15px;
      border: none;
      width: auto;
      text-transform: none;
      padding: 0;
      margin-left: 0!important;
      font-size: 16px;
      @media screen and (max-width: 1499px) {
        display: block;
        margin-top: 15px;
        width: auto;
      }

      &:hover{
        opacity: .9;
        border: none;
      }
    }
    a{
      color: #F8C000;
      border: 1px solid #03A9F5;
      display: inline-block;
      padding: 0.7em 1.8em;
      line-height: 100%;
      white-space: nowrap;
      letter-spacing: 0.3px;
      transition: all, 0.3s;
      box-sizing: border-box;
      text-align: center;
      width: 130px;
      margin-top: 15px;
      @media screen and (max-width: 1499px) {
        display: block;
        margin-top: 15px;
        width: 230px;
      }
      @media screen and (max-width: 575px) {
        width: 100%;
      }

      &:hover{
        border: 1px solid #F8C000;
      }
    }
    a:last-child{
      margin-left: 50px;
      @media screen and (max-width: 1579px) {
        margin-left: 20px;
      }
      @media screen and (max-width: 1499px) {
        margin-top: 15px;
        margin-left: 0;
      }
    }
  }

  &__title {
    font-size: 1.6em;
    letter-spacing: 0.3px;
    font-weight: 800;
    word-spacing: 1px;
    margin-bottom: 1.4em;
  }

  &__header {
    position: relative;
    background-color: #fff;
    padding-bottom: 40%;
    overflow: hidden;
    width: 100%;

    @media screen and (min-width: 1441px) {
      position: absolute;
      padding-bottom: 35%;
      width: 47%;
      top: -3.2em;
      right: -12%;
      transform: translateX(100%) skewX(-35deg);
      transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;

    @media screen and (min-width: 1441px) {
      left: -26%;
      width: 102%;
      transform: skewX(35deg);
    }
  }

  &__footer {
    padding: 0 2.5em 2.5em;
    overflow: auto;
    opacity: 0;
    transition: opacity 0.55s;

    @media screen and (min-width: 1441px) {
      padding: 3.8em 0 0.5em;
    }

    &::-webkit-scrollbar-track
    {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #333;
    }

    &::-webkit-scrollbar
    {
      width: 3px;
      background-color: #000;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #E42D19;
      border-radius: 0;
    }

    &-content {
      margin: 0 auto;
      max-width: 490px;
    }

    .button {
      min-width: 224px;

      @media screen and (max-width: 1200px) {
        margin-bottom: 1em;
      }
    }
  }

  &__video {
    position: relative;
    padding-bottom: 55.5%;
    margin-bottom: 4.8em;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  &__links {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    a {
      @media screen and (min-width: 1201px) {
        &:not(:last-child) {
          margin-right: 1.5em;
        }
      }
    }

    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
  }

  p {
    margin-bottom: 1.5em;
  }

  ul {
    list-style: none;
    padding: 0 1em 0 0;
    margin: 0;

    li {
      display: flex;
      line-height: 1.3;
      margin-bottom: 1.15em;

      &::before {
        content: '';
        display: inline-block;
        width: 11px;
        max-width: 11px;
        flex: 0 0 11px;
        height: 8px;
        background-color: #90C241;
        transform: skewX(-31deg);
        margin: 0.3em 1em 0 0.2em;
      }
    }
  }
}

@font-face {
	font-family: 'HelveticaNeueLTStd';
	src: local('HelveticaNeueLTStdRoman'), local('HelveticaNeueLTStdRoman'), url('../fonts/Helvetica Neue LT Std 55 Roman/Helvetica Neue LT Std 55 Roman.woff2') format('woff2'), url('../fonts/Helvetica Neue LT Std 55 Roman/Helvetica Neue LT Std 55 Roman.woff') format('woff'),
	url('../fonts/Helvetica Neue LT Std 55 Roman/Helvetica Neue LT Std 55 Roman.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'HelveticaNeueLTStd';
	src: local('HelveticaNeueLTStdLight'), local('HelveticaNeueLTStdLight'), url('../fonts/Helvetica Neue LT Std 45 Light/Helvetica Neue LT Std 45 Light.woff2') format('woff2'), url('../fonts/Helvetica Neue LT Std 45 Light/Helvetica Neue LT Std 45 Light.woff') format('woff'),
	url('../fonts/Helvetica Neue LT Std 45 Light/Helvetica Neue LT Std 45 Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'HelveticaNeueLTStd';
	src: local('HelveticaNeueLTStdBold'), local('HelveticaNeueLTStdBold'), url('../fonts/Helvetica Neue LT Std 75 Bold/Helvetica Neue LT Std 75 Bold.woff2') format('woff2'), url('../fonts/Helvetica Neue LT Std 75 Bold/Helvetica Neue LT Std 75 Bold.woff') format('woff'),
	url('../fonts/Helvetica Neue LT Std 75 Bold/Helvetica Neue LT Std 75 Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
@font-face {
  font-family: 'HelveticaNeueLTStd';
  src: local('HelveticaNeueLTStdHeavy'), local('HelveticaNeueLTStdHeavy'), url('../fonts/Helvetica Neue LT Std 85 Heavy/Helvetica Neue LT Std 85 Heavy.woff2') format('woff2'), url('../fonts/Helvetica Neue LT Std 85 Heavy/Helvetica Neue LT Std 85 Heavy.woff') format('woff'),
  url('../fonts/Helvetica Neue LT Std 85 Heavy/Helvetica Neue LT Std 85 Heavy.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'BebasNeueBold';
  src: local('BebasNeueBold'), local('BebasNeueBold'), url('../fonts/BebasNueBold/BebasNeueBold.woff') format('woff2'), url('../fonts/BebasNueBold/BebasNeueBold.woff') format('woff'),
  url('../fonts/BebasNueBold/BebasNeueBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
